import styled from "styled-components";
import favoritesIcon from "../../../../assets/images/aba-favoritos-icon.png";

export const Container = styled.div`
  // width: 935px;
  // padding: 20px 40px;
  // @media only screen and (max-width: 600px) {
  //   width: 100%;
  //   padding: 20px 20px;
  // }
`;

export const AllBlocks = styled.div`
  user-select: text;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 60vw;
  height: 100%;
  &.mobile {
    width: 90vw;
  }
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    flex-flow: row wrap;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.8s ease-in-out;

  &.mobile {
    width: 100%;
    &.on {
      width: 0px;
      transform: translateX(100vw);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    overflow-y: hidden;
  }
`;

export const RightBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 300px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;

export const FirstBlock = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 40px 40px 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FirstBlockTop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  padding: 1vw;

  @media only screen and (max-width: 600px) {
    flex-flow: column-reverse wrap;
  }
`;

export const FirstBlockCarrousel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  &.mobile {
    width: 100%;
  }
  max-width: 343px;
`;

export const FirstBlockImage = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FirstBlockCarrouselBullets = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  overflow-x: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const FirstBlockCarrouselBullet = styled.div`
  cursor: pointer;
  pointer-events: all;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  background-color: ${({ theme }) => theme.colors.greylightest};
  border-radius: 8px;
  object-fit: contain;

  &.on {
    background-color: ${({ theme }) => theme.colors.greyDark};
  }
`;

export const FirstBlockTopRight = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  width: 50%;
  margin-left: 2vw;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 20px 0px 0px 0px;
  }
`;

export const FirstBlockMenu = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
  margin-bottom: 30px;
  ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.bodytitle};
  @media only screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const MenuIcon = styled.div`
  pointer-events: all;
  cursor: pointer;
  width: 40px;
  aspect-ratio: 1 / 1;
`;
export const FirstBlockTopRightText = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.black};

  @media only screen and (max-width: 600px) {
    // font-size: 32px;
    // line-height: 40px;
  }
`;
export const FirstBlockTitle = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.h3};
  font-size: 32px;
  text-transform: none;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    font-size: 28px;
    line-height: 30px;
  }
`;

export const FirstBlockText = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 0px;
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SecondBlock = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.offwhite};
  padding: 40px 30px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const ThirdBlock = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 40px 30px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FirstBlockRight = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 40px 30px 20px;
`;

export const FirstBlockRightTitle = styled.div`
  ${({ theme }) => theme.fonts.caption};
  color: ${({ theme }) => theme.colors.offwhite};
  font-size: 14px;
`;

export const FirstBlockRightThumbs = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const FirstBlockRightThumbContainer = styled.div`
  width: 160px;
  height: auto;
  padding-bottom: 20px;

  @media only screen and (max-width: 600px) {
    width: 129px;
    margin-right: 20px;
  }
`;

export const FirstBlockRightThumb = styled.img`
  width: 160px;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: contain;
  @media only screen and (max-width: 600px) {
    width: 129px;
    height: 129px;
  }
`;

export const FirstBlockRightThumbTitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.offwhite};

  // &:after {
  //   content: " ↗";
  //   white-space: pre;
  //   color: ${({ theme }) => theme.colors.offwhite};
  // }
`;

export const SecondBlockRight = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 40px 30px 20px;
`;

export const SecondBlockRightTitle = styled.div`
  ${({ theme }) => theme.fonts.caption};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
`;

export const SecondBlockRightThumbs = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 40px;
`;

export const SecondBlockRightThumbContainer = styled.div`
  width: 160px;
  height: auto;
  padding-bottom: 40px;
  @media only screen and (max-width: 600px) {
    width: 129px;
    margin-right: 20px;
  }
`;

export const SecondBlockRightThumb = styled.div`
  width: 160px;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.offwhite};
  border-radius: 8px;
  margin-bottom: 10px;
  @media only screen and (max-width: 600px) {
    width: 129px;
    height: 129px;
  }
`;

export const SecondBlockRightThumbTitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.offwhite};

  &:after {
    content: " ↗";
    white-space: pre;
    color: ${({ theme }) => theme.colors.sky};
  }
`;

export const SecondBlockRightThumbSubtitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.greylight};
`;

export const ThirdBlockLeftTitle = styled.div`
  ${({ theme }) => theme.fonts.caption};
  color: ${({ theme }) => theme.colors.blue};
  font-size: 14px;
`;

export const ThirdBlockLeftThumbs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-top: 40px;
  overflow-x: auto;
  cursor: grab;

  // overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ThirdBlockLeftThumbContainer = styled.div`
  width: 170px;
  padding: 0px 10px 40px;
  margin-right: 20px;
  @media only screen and (max-width: 600px) {
    width: 129px;
  }
`;

export const ThirdBlockLeftThumb = styled.img`
  width: 160px;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  object-fit: contain;

  @media only screen and (max-width: 600px) {
    width: 129px;
    height: 129px;
  }
`;

export const ThirdBlockLeftThumbTitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.blue};
  margin-top: 10px;
  // &:after {
  //   content: " ↗";
  //   white-space: pre;
  //   color: ${({ theme }) => theme.colors.sky};
  // }
`;

export const ThirdBlockLeftThumbSubtitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.blue};
`;

export const ThirdBlockLeftThumbSpecialties = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.blue};
  // background-color: pink;
`;

export const ThirdBlockLeftThumbSpecialty = styled.img`
  image-rendering: auto;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: contain;
  margin: 0px 4px 8px;

  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

export const TabContainer = styled.div`
  padding: 5% 8%;
  display: flex;
  flex-flow: row wrap;
`;

export const TabContainerTitle = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.h3};
  font-size: 22px;
  text-transform: none;
  letter-spacing: 0px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.blue};
  @media only screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const TabItem = styled.div`
  display: grid;
  grid-template-columns: 150px repeat(1, 1fr);
  grid-column-gap: 2rem;
  width: 100%;
  margin: 30px 0px;
  padding: 0;

  a {
    text-decoration: none;
  }
`;

export const TabItemImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #bbb7a3;
  border-radius: 10px;
`;

export const TabItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 2px solid #bbb7a3;
  border-radius: 10px;
`;

export const TabItemTexts = styled.div`
  width: 100%;
  height: 100%;
`;

export const TabItemTitle = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.h3};
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TabItemDescription = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.black};
  line-height: 26px;
  white-space: pre-wrap;

  @media only screen and (max-width: 600px) {
    // font-size: 32px;
    // line-height: 40px;
  }
`;

export const TabItemDownloads = styled.div`
  display: grid;
  width: 100%;
  margin: 30px 0px;
  padding: 0;

  a {
    text-decoration: none;
  }
`;

export const TabItemDownloadsImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #bbb7a3;
  border-radius: 10px;
`;

export const TabItemDownloadsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 2px solid #bbb7a3;
  border-radius: 10px;
`;

export const TabItemDownloadsTexts = styled.div`
  width: 100%;
  height: 100%;
`;

export const TabItemDownloadsTitle = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.h3};
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TabItemDownloadsDescription = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.black};
  line-height: 26px;

  @media only screen and (max-width: 600px) {
    // font-size: 32px;
    // line-height: 40px;
  }
`;

export const TabFavorites = styled.div`
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  width: 505px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  right: calc(60vw - 443px);
  &.mobile {
    right: calc(60vw - 60%);
  }
  z-index: -1;
  transition: all 0.3s ease-in-out;

  &.on {
    right: calc(60vw);

    &.mobile {
      right: calc(60vw - 60%);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    overflow-y: hidden;
  }
`;

export const TabFavoritesIcon = styled.div`
  cursor: pointer;
  pointer-events: all;
  width: 10vw;
  height: 100vh;

  background-image: url(${favoritesIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  &.mobile {
    height: 100vh;
    left: 100;
    z-index: 10;
  }
`;

export const TabFavoritesContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 60vw;
  height: 100vh;
  &.mobile {
    width: 90vw;
  }
  padding: 20px 40px 40px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.blue};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabFavoritesContentTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  width: 100%;
  ${({ theme }) => theme.fonts.h3};
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.white};
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TabFavoritesContentItens = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
`;

export const TabFavoritesContentItem = styled.div`
  flex-shrink: 0;
  display: grid;
  grid-template-rows: 120px auto;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  align-content: center;
  width: 350px;
  height: 300px;
  &.mobile {
    width: 100%;
  }
  margin: 0px;
  border-bottom: 1px solid #000022;
`;

export const TabFavoritesContentItemTop = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  margin: 0px;
  column-gap: 20px;
`;

export const TabFavoritesContentItemImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;

  border-radius: 15px;
`;

export const TabFavoritesContentItemTitle = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.h3};
  font-size: 18px;
  line-height: 20px;
  text-transform: none;
  letter-spacing: 0px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.white};
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TabFavoritesContentItemText = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${({ theme }) => theme.fonts.body};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};
  @media only screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
  }

  &.mobile {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Icon = styled.div`
  cursor: pointer;
  padding: 15px 0px;
`;
