import React, { useState, useEffect } from "react";
import RoutesSaintGobain from "./Routes";
import PreloadProvider from "./contexts/preload";
import UserProvider from "./contexts/user";
import Preloader from "./shared/Components/Preloader";
import { GlobalStyles } from "./theme/GlobalStyles";
import Theme from "./theme";
import FavoritesProvider from "./contexts/favorites";

function App() {
  return (
    <>
      <Theme>
        <GlobalStyles />
        <UserProvider>
          <FavoritesProvider>
            <PreloadProvider>
              <Preloader />
              <RoutesSaintGobain />
            </PreloadProvider>
          </FavoritesProvider>
        </UserProvider>
      </Theme>
    </>
  );
}

export default App;
