const productsEscola = [
  {
    id: 3,
    name: "Placa Gesso Performa Placo",
    pinPos: [-60.56999759222776, 383.3168791970614, -724.8474019485492],
    scale: 1.3,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -213.96488922472057,
        y: 248.63881042268937,
        z: -338.35367266346077,
      },
      target: {
        x: -213.7788974273974,
        y: 249.00184728987531,
        z: -339.26669582000847,
      },
      posMobile: {
        x: -213.96488922472057,
        y: 248.63881042268937,
        z: -338.35367266346077,
      },
      targetMobile: {
        x: -213.7788974273974,
        y: 249.00184728987531,
        z: -339.26669582000847,
      },
    },
    cameraOut: {
      pos: {
        x: 74.10008933987947,
        y: 230.50363959303024,
        z: 174.87033987868784,
      },
      target: {
        x: 73.91884716563165,
        y: 230.67592478669263,
        z: 173.902110006024,
      },
    },
  },
  {
    id: 6,
    name: "supergraute quartzolit",
    pinPos: [327.6303677685645, 347.2443860826796, -789.0062151962369],
    scale: 1.3,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 120.23428071241158,
        y: 327.47217775192115,
        z: -403.9854563985825,
      },
      target: {
        x: 120.55480070728696,
        y: 327.5214231234259,
        z: -404.9314171938962,
      },
      posMobile: {
        x: 120.23428071241158,
        y: 327.47217775192115,
        z: -403.9854563985825,
      },
      targetMobile: {
        x: 120.55480070728696,
        y: 327.5214231234259,
        z: -404.9314171938962,
      },
    },
    cameraOut: {
      pos: {
        x: 73.70599064367447,
        y: 230.53206671887506,
        z: 174.86854518614945,
      },
      target: {
        x: 73.91884716563165,
        y: 230.67592478669263,
        z: 173.902110006024,
      },
    },
  },
  {
    id: 27,
    name: "Painel Fiberwood Prime Sonex",
    pinPos: [-61.12360053591752, 278.44666018681966, 656.7693224349196],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -178.6733091949026,
        y: 226.16145417404326,
        z: 397.5894557889072,
      },
      target: {
        x: -178.1395214428654,
        y: 226.30637412716203,
        z: 398.42256373087093,
      },
      posMobile: {
        x: -178.6733091949026,
        y: 226.16145417404326,
        z: 397.5894557889072,
      },
      targetMobile: {
        x: -178.1395214428654,
        y: 226.30637412716203,
        z: 398.42256373087093,
      },
    },
    cameraOut: {
      pos: {
        x: 74.10202528216318,
        y: 230.5443606236001,
        z: 173.0327962262242,
      },
      target: {
        x: 73.8257393484007,
        y: 230.67431072047776,
        z: 173.98504569728024,
      },
    },
  },
  {
    id: 35,
    name: "Nuvens Illtec Sonex",
    pinPos: [-463.08298013615683, 413.677070831982, -428.5900146929238],
    scale: 1.2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -516.5646340212525,
        y: 158.8589498765313,
        z: -241.9675423602017,
      },
      target: {
        x: -516.5401215350968,
        y: 159.66616915538245,
        z: -242.55728482422606,
      },
      posMobile: {
        x: -516.5646340212525,
        y: 158.8589498765313,
        z: -241.9675423602017,
      },
      targetMobile: {
        x: -516.5401215350968,
        y: 159.66616915538245,
        z: -242.55728482422606,
      },
    },
    cameraOut: {
      pos: {
        x: 74.57432381340357,
        y: 230.44477247499262,
        z: 174.64294603729272,
      },
      target: {
        x: 73.90596088834788,
        y: 230.66885580086253,
        z: 173.93366635798554,
      },
    },
  },
  {
    id: 36,
    name: "Forro Advantage A Ecophon",
    pinPos: [95.2711975793128, 430.1626461415416, 379.5826817018478],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 190.95737747732272,
        y: 189.12727724039848,
        z: 208.9050199737524,
      },
      target: {
        x: 190.81237147309176,
        y: 189.92457605375893,
        z: 209.490927697674,
      },
      posMobile: {
        x: 190.95737747732272,
        y: 189.12727724039848,
        z: 208.9050199737524,
      },
      targetMobile: {
        x: 190.81237147309176,
        y: 189.92457605375893,
        z: 209.490927697674,
      },
    },
    cameraOut: {
      pos: {
        x: 73.54658983917928,
        y: 229.99344766625046,
        z: 173.4006021330217,
      },
      target: {
        x: 73.66814697563748,
        y: 230.67157878239365,
        z: 174.12542076681532,
      },
    },
  },
  {
    id: 39,
    name: "impermeabilizante tecplus top quartzolit",
    pinPos: [-504.48448383114487, 27.853970494808685, -467.75856177095744],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -384.1468235252209,
        y: 160.869018404139,
        z: -209.89242410815964,
      },
      target: {
        x: -384.68279001127934,
        y: 160.49507014794045,
        z: -210.64932742266436,
      },
      posMobile: {
        x: -384.1468235252209,
        y: 160.869018404139,
        z: -209.89242410815964,
      },
      targetMobile: {
        x: -384.68279001127934,
        y: 160.49507014794045,
        z: -210.64932742266436,
      },
    },
    cameraOut: {
      pos: {
        x: 72.4937654168445,
        y: 230.80719116527732,
        z: 172.06624442535116,
      },
      target: {
        x: 71.81875900226451,
        y: 230.56621516182867,
        z: 171.36889450885457,
      },
    },
  },
  {
    id: 45,
    name: "epóxi SF 250 quartzolit",
    pinPos: [-123.3464530211623, 25.483968651158648, -532.4270733123924],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -6.093316339267018,
        y: 381.069572357346,
        z: -243.11889951327836,
      },
      target: {
        x: -6.485748923172739,
        y: 380.303527914227,
        z: -243.62798946432397,
      },
      posMobile: {
        x: -6.093316339267018,
        y: 381.069572357346,
        z: -243.11889951327836,
      },
      targetMobile: {
        x: -6.485748923172739,
        y: 380.303527914227,
        z: -243.62798946432397,
      },
    },
    cameraOut: {
      pos: {
        x: 73.5678674360273,
        y: 230.958942631112,
        z: 174.06891514341274,
      },
      target: {
        x: 73.30191009406201,
        y: 230.6436956946634,
        z: 173.15793431962194,
      },
    },
  },
  {
    id: 19,
    name: "Linha Wallfelt Isover",
    pinPos: [-570.9769437959578, 274.9519835137294, -477.06698313799615],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -407.59174654405854,
        y: 228.47853139552203,
        z: -351.12596168078454,
      },
      target: {
        x: -408.4439422959468,
        y: 228.74127179658186,
        z: -351.5784325440614,
      },
      posMobile: {
        x: -407.59174654405854,
        y: 228.47853139552203,
        z: -351.12596168078454,
      },
      targetMobile: {
        x: -408.4439422959468,
        y: 228.74127179658186,
        z: -351.5784325440614,
      },
    },
    cameraOut: {
      pos: {
        x: 74.70072262989626,
        y: 230.60488404935967,
        z: 174.7094509759814,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  /// TEST PRODUCT
  // {
  //   id: 156,
  //   name: "Test product",
  //   pinPos: [-463.08298013615683, 350.677070831982, -428.5900146929238],
  //   scale: 1.2,
  //   animationSpeed: 0.1,
  //   selected: false,
  //   hovered: false,
  //   cameraIn: {
  //     pos: {
  //       x: -516.5646340212525,
  //       y: 158.8589498765313,
  //       z: -241.9675423602017,
  //     },
  //     target: {
  //       x: -516.5401215350968,
  //       y: 159.66616915538245,
  //       z: -242.55728482422606,
  //     },
  //     posMobile: {
  //       x: -516.5646340212525,
  //       y: 158.8589498765313,
  //       z: -241.9675423602017,
  //     },
  //     targetMobile: {
  //       x: -516.5401215350968,
  //       y: 159.66616915538245,
  //       z: -242.55728482422606,
  //     },
  //   },
  //   cameraOut: {
  //     pos: {
  //       x: 74.57432381340357,
  //       y: 230.44477247499262,
  //       z: 174.64294603729272,
  //     },
  //     target: {
  //       x: 73.90596088834788,
  //       y: 230.66885580086253,
  //       z: 173.93366635798554,
  //     },
  //   },
  // },
];

export default productsEscola;
