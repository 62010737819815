import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { MenuHolder, Menu, MenuLines, MenuLine } from "./style";

const CloseInterna = (props) => {
  return (
    <MenuHolder
      {...props}
      className={`${isMobile ? "mobile" : ""} ${props?.classe}`}
    >
      <Menu />
      <MenuLines className={isMobile && "mobile"}>
        <MenuLine />
        <MenuLine />
        <MenuLine />
      </MenuLines>
    </MenuHolder>
  );
};

export default CloseInterna;
