import { useEffect, forwardRef } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import { isMobile, isBrowser } from "react-device-detect";

const CameraControls = forwardRef((props, refOrbit) => {
  const {
    camera,
    gl: { domElement },
    performance,
  } = useThree();

  useEffect(() => {
    // performance.debounce = 20;

    // console.log("reforbit", refOrbit);
    // console.log(props.data.entryPos[props.local].camPosX);
    if (isMobile) {
      camera.position.x = props.data.entryPos[props.local].camPosX;
      camera.position.y = props.data.entryPos[props.local].camPosY;
      camera.position.z = props.data.entryPos[props.local].camPosZ;
      refOrbit.current.target = new THREE.Vector3(
        props.data.entryPos[props.local].targetPosX,
        props.data.entryPos[props.local].targetPosY,
        props.data.entryPos[props.local].targetPosZ
      );
      refOrbit.current.update();
      // props.setIsAnimating(false);
      refOrbit.current.addEventListener("start", () => {
        // console.log(
        //   "reforbit",
        //   refOrbit.current.object.position,
        //   refOrbit.current.target
        // );
        if (props.isAnimating) {
          props.setIsAnimating(false);
        }
      });
    } else {
      refOrbit.current.object.position.x =
        props.data.entryPos[props.local].camPosX;
      refOrbit.current.object.position.y =
        props.data.entryPos[props.local].camPosY;
      refOrbit.current.object.position.z =
        props.data.entryPos[props.local].camPosZ;
      refOrbit.current.target = new THREE.Vector3(
        props.data.entryPos[props.local].targetPosX,
        props.data.entryPos[props.local].targetPosY,
        props.data.entryPos[props.local].targetPosZ
      );
      refOrbit.current.update();
      // props.setIsAnimating(false);
      refOrbit.current.addEventListener("start", () => {
        // console.log(refOrbit.current.object.position, refOrbit.current.target);
        if (props.isAnimating) {
          props.setIsAnimating(false);
        }
      });
      refOrbit.current.addEventListener("change", () => {
        // console.log(
        //   `pos: {
        //       x: ${refOrbit.current.object.position.x},
        //       y: ${refOrbit.current.object.position.y},
        //       z: ${refOrbit.current.object.position.z},
        //     },
        //     target: {
        //       x: ${refOrbit.current.target.x},
        //       y: ${refOrbit.current.target.y},
        //       z: ${refOrbit.current.target.z},
        //     },
        //     posMobile: {
        //       x: ${refOrbit.current.object.position.x},
        //       y: ${refOrbit.current.object.position.y},
        //       z: ${refOrbit.current.object.position.z},
        //     },
        //     targetMobile: {
        //       x: ${refOrbit.current.target.x},
        //       y: ${refOrbit.current.target.y},
        //       z: ${refOrbit.current.target.z},
        //     },
        //   `
        // );
        if (props.isAnimating) {
          // props.setIsAnimating(false);
        }
      });
    }
  }, []);

  return (
    <>
      <OrbitControls
        makeDefault
        ref={refOrbit}
        args={[camera, domElement]}
        minAzimuthAngle={
          props.minAzimuthAngle ? props.minAzimuthAngle : Math.infinity
        }
        maxAzimuthAngle={
          props.maxAzimuthAngle ? props.maxAzimuthAngle : Math.infinity
        }
        minPolarAngle={props.data.minPolarAngle}
        // maxPolarAngle={props.data.maxPolarAngle}
        minDistance={1}
        maxDistance={1}
        enableZoom={props.data.enableZoom}
        enablePan={props.data.enablePan}
        panSpeed={200.0}
        enableRotate={props.data.enableRotate}
        rotateSpeed={props.data.rotateSpeed * -1}
        autoRotate={props.data.autoRotate}
        enableDamping={props.data.enableDamping}
        dampingFactor={props.data.dampingFactor}
        // regress
      />
    </>
  );
});

export default CameraControls;
