const productsPonte = [
  {
    id: 157,
    name: "Argamassa S90IC",
    pinPos: [-650, 95, -5750],
    scale: 2.5,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -956.9534370152619,
        y: 31.39675453161144,
        z: -6236.824774906112,
      },
      target: {
        x: -956.2170385709061,
        y: 31.462529466793455,
        z: -6236.151431762498,
      },
      posMobile: {
        x: -956.9534370152619,
        y: 31.39675453161144,
        z: -6236.824774906112,
      },
      targetMobile: {
        x: -956.2170385709061,
        y: 31.462529466793455,
        z: -6236.151431762498,
      },
    },
    cameraOut: {
      pos: {
        x: -1554.5564590216916,
        y: 1361.3075659029005,
        z: -6860.544720591953,
      },
      target: {
        x: -1554,
        y: 1360.6801642501448,
        z: -6860,
      },
    },
  },
  {
    id: 158,
    name: "Graute Subaquático",
    pinPos: [-570, -180, -5950],
    scale: 2.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -853.0956101461869,
        y: 156.5393887939786,
        z: -6317.12949372761,
      },
      target: {
        x: -852.419212587496,
        y: 155.97698040547948,
        z: -6316.653906567204,
      },
      posMobile: {
        x: -853.0956101461869,
        y: 156.5393887939786,
        z: -6317.12949372761,
      },
      targetMobile: {
        x: -852.419212587496,
        y: 155.97698040547948,
        z: -6316.653906567204,
      },
    },
    cameraOut: {
      pos: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      target: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
      posMobile: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      targetMobile: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
    },
  },
  {
    id: 163,
    name: "Fast Set quartzolit",
    pinPos: [-500, 180, -5950],
    scale: 2.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -915.3176428499731,
        y: 432.8392857217809,
        z: -6328.2974144473665,
      },
      target: {
        x: -914.4869999722005,
        y: 432.4540921552089,
        z: -6327.895346883545,
      },
      posMobile: {
        x: -915.3176428499731,
        y: 432.8392857217809,
        z: -6328.2974144473665,
      },
      targetMobile: {
        x: -914.4869999722005,
        y: 432.4540921552089,
        z: -6327.895346883545,
      },
    },
    cameraOut: {
      pos: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      target: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
      posMobile: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      targetMobile: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
    },
  },
  {
    id: 144,
    name: "Pintura PU quartzolit",
    pinPos: [-540, -60, -6200],
    scale: 2.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -1268.295941707819,
        y: 234.38547263024486,
        z: -6030.983540595562,
      },
      target: {
        x: -1267.488887124437,
        y: 233.99881724857661,
        z: -6031.4298146316305,
      },
      posMobile: {
        x: -1268.295941707819,
        y: 234.38547263024486,
        z: -6030.983540595562,
      },
      targetMobile: {
        x: -1267.488887124437,
        y: 233.99881724857661,
        z: -6031.4298146316305,
      },
    },
    cameraOut: {
      pos: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      target: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
      posMobile: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      targetMobile: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
    },
  },
];

export default productsPonte;
