import { useEffect, useRef } from "react";
import * as THREE from "three";

export default function CubesCity({ texture }) {
  const radius = 2500; // Raio do círculo
  const meshRef = useRef();
  const numCubes = 1000;

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = texture.image;

    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);
    const imageData = context.getImageData(0, 0, image.width, image.height);

    const isAreaBlack = (x, z, size) => {
      const width = imageData.width;
      const height = imageData.height;
      const halfSize = size / 2;

      for (let dx = -halfSize; dx <= halfSize; dx += 1) {
        for (let dz = -halfSize; dz <= halfSize; dz += 1) {
          const pixelX = Math.floor(((x + dx) / (2 * radius) + 0.5) * width);
          const pixelY = Math.floor(((z + dz) / (2 * radius) + 0.5) * height);

          if (pixelX < 0 || pixelX >= width || pixelY < 0 || pixelY >= height) {
            return false; // Fora dos limites da textura
          }

          const pixelIndex = (pixelY * width + pixelX) * 4;
          const r = imageData.data[pixelIndex];
          const g = imageData.data[pixelIndex + 1];
          const b = imageData.data[pixelIndex + 2];

          if (r >= 250 || g >= 250 || b >= 250) {
            return false; // Não é preto
          }
        }
      }
      return true;
    };

    const positions = [];
    const scales = [];
    const colors = [];

    while (positions.length < numCubes) {
      const angle = Math.random() * 2 * Math.PI;
      const distance = Math.sqrt(Math.random()) * radius;
      const x = Math.cos(angle) * distance;
      const z = Math.sin(angle) * distance;
      const size = 5 + (Math.random() * 15 + 0.5);

      if (isAreaBlack(x, z, size)) {
        positions.push([x, 8 + size / 2, 3416 + z]);
        scales.push([size, size * 7, size]);
        colors.push(
          // new THREE.Color(Math.random(), Math.random(), Math.random())
          new THREE.Color(1, 1, 1)
        );
      }
    }

    const mesh = meshRef.current;
    positions.forEach((position, i) => {
      const matrix = new THREE.Matrix4();
      const color = colors[i];
      matrix.compose(
        new THREE.Vector3(...position),
        new THREE.Quaternion(),
        new THREE.Vector3(...scales[i])
      );
      mesh.setMatrixAt(i, matrix);
      mesh.setColorAt(i, color);
    });
    // mesh.instanceMatrix.needsUpdate = true;
    // mesh.instanceColor.needsUpdate = true;
  }, [texture]);

  return (
    <instancedMesh
      ref={meshRef}
      args={[null, null, numCubes]}
      castShadow
      receiveShadow
      position={[0, 0, -1460]}
      scale={[1.5, 1, 1.4]}
    >
      <boxGeometry />
      <meshPhysicalMaterial
        color={new THREE.Color("#ffffff")}
        // vertexColor
        transmission={0.5}
        roughness={0}
        metalness={0}
        ior={1.5}
        clearcoat={0.4}
        thickness={0.1}
        specularIntensity={1}
        specularColor={new THREE.Color("#ffffff")}
      />
    </instancedMesh>
  );
}
