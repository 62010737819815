const productsAviario = [
  {
    id: 159,
    name: "Wallboard Brasilit",
    pinPos: [2160, 165, -8297],
    scale: 0.6,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 2291.06453627373,
        y: 160.45875305909374,
        z: -8456.184552144077,
      },
      target: {
        x: 2290.6421136497534,
        y: 160.4698738878695,
        z: -8455.278221379996,
      },
      posMobile: {
        x: 2291.06453627373,
        y: 160.45875305909374,
        z: -8456.184552144077,
      },
      targetMobile: {
        x: 2290.6421136497534,
        y: 160.4698738878695,
        z: -8455.278221379996,
      },
    },
    cameraOut: {
      pos: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      target: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
      posMobile: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      targetMobile: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
    },
  },
  {
    id: 160,
    name: "Telha térmica TopComfort Agro",
    pinPos: [2100, 245, -8300],
    scale: 0.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 2128.24609564943,
        y: 287.46213235820875,
        z: -8442.805374360576,
      },
      target: {
        x: 2128.314764944547,
        y: 287.1272168684645,
        z: -8441.865631766757,
      },
      posMobile: {
        x: 2128.24609564943,
        y: 287.46213235820875,
        z: -8442.805374360576,
      },
      targetMobile: {
        x: 2128.314764944547,
        y: 287.1272168684645,
        z: -8441.865631766757,
      },
    },
    cameraOut: {
      pos: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      target: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
      posMobile: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      targetMobile: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
      posMobile: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      targetMobile: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
    },
  },
  {
    id: 161,
    name: "Midfelt Agro Isover",
    pinPos: [2160, 235, -8070],
    scale: 0.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 2386.4567752451576,
        y: 260.956278190102,
        z: -8149.169763003263,
      },
      target: {
        x: 2385.6442568772623,
        y: 260.8048257005362,
        z: -8148.606845562392,
      },
      posMobile: {
        x: 2386.4567752451576,
        y: 260.956278190102,
        z: -8149.169763003263,
      },
      targetMobile: {
        x: 2385.6442568772623,
        y: 260.8048257005362,
        z: -8148.606845562392,
      },
    },
    cameraOut: {
      pos: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      target: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
      posMobile: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      targetMobile: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
      posMobile: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      targetMobile: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
    },
  },
  {
    id: 162,
    name: "Manta Telhado Brasilit",
    pinPos: [1900, 245, -8300],
    scale: 0.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 1873.9631902806955,
        y: 315.93314797582616,
        z: -8475.667795997708,
      },
      target: {
        x: 1874.3466152622357,
        y: 315.5371039614809,
        z: -8474.833449293854,
      },
      posMobile: {
        x: 1873.9631902806955,
        y: 315.93314797582616,
        z: -8475.667795997708,
      },
      targetMobile: {
        x: 1874.3466152622357,
        y: 315.5371039614809,
        z: -8474.833449293854,
      },
    },
    cameraOut: {
      pos: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      target: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
      posMobile: {
        x: 2359.7055181792794,
        y: 276.84712187855735,
        z: -8558.688744690739,
      },
      targetMobile: {
        x: 2359,
        y: 276.6801642501449,
        z: -8558,
      },
      posMobile: {
        x: -1543.7531862471342,
        y: 1366.9615926741794,
        z: -6729.650157313275,
      },
      targetMobile: {
        x: -1543.1189157001616,
        y: 1366.3948237406778,
        z: -6729.124349506885,
      },
    },
  },
];

export default productsAviario;
