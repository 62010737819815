const productsIndustria = [
  {
    id: 65,
    name: "graute EP quartzolit",
    pinPos: [28.08862368998539, 42.90362482244333, 896.9618920830907],
    scale: 1.4,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 370.93751866068004,
        y: 173.4449718229009,
        z: 797.8135833656183,
      },
      target: {
        x: 370.12099568363374,
        y: 173.03661026218523,
        z: 798.2216655432312,
      },
      posMobile: {
        x: 370.93751866068004,
        y: 173.4449718229009,
        z: 797.8135833656183,
      },
      targetMobile: {
        x: 370.12099568363374,
        y: 173.03661026218523,
        z: 798.2216655432312,
      },
    },
    cameraOut: {
      pos: {
        x: 691.7252025490385,
        y: 237.03617468375947,
        z: 189.84290169799652,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 9,
    name: "pintura EP SF P235 quartzolit",
    pinPos: [822.8144332492084, 567.3457674319727, -3173.6252851141535],
    animationSpeed: 0.1,
    scale: 3,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 245.01161687712298,
        y: 150.70750023633127,
        z: -2631.948272708183,
      },
      target: {
        x: 245.52698092402932,
        y: 151.19175752198802,
        z: -2632.655305083994,
      },
      posMobile: {
        x: 245.01161687712298,
        y: 150.70750023633127,
        z: -2631.948272708183,
      },
      targetMobile: {
        x: 245.52698092402932,
        y: 151.19175752198802,
        z: -2632.655305083994,
      },
    },
    cameraOut: {
      pos: {
        x: 691.0010663087326,
        y: 236.7869157142098,
        z: 191.56881271151462,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 10,
    name: "pintura EP quartzolit",
    pinPos: [355.50953654248485, 888.9187381025682, -3372.847103033082],
    scale: 3,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 324.8130358804401,
        y: 287.8204993524525,
        z: -2647.336950017472,
      },
      target: {
        x: 324.7001357955914,
        y: 288.4802523122521,
        z: -2648.079903315383,
      },
      posMobile: {
        x: 324.8130358804401,
        y: 287.8204993524525,
        z: -2647.336950017472,
      },
      targetMobile: {
        x: 324.7001357955914,
        y: 288.4802523122521,
        z: -2648.079903315383,
      },
    },
    cameraOut: {
      pos: {
        x: 691.2185394930272,
        y: 236.69579625980575,
        z: 191.5473110050117,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 22,
    name: "Feltro Isotech Isover",
    pinPos: [-396.5404404092334, 1617.099885461818, -2939.415670461305],
    scale: 4,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 496.4002562309541,
        y: 2530.0767148882956,
        z: -2080.52826281959,
      },
      target: {
        x: 495.7027622997643,
        y: 2529.5034940372907,
        z: -2080.9582861584063,
      },
      posMobile: {
        x: 496.4002562309541,
        y: 2530.0767148882956,
        z: -2080.52826281959,
      },
      targetMobile: {
        x: 495.7027622997643,
        y: 2529.5034940372907,
        z: -2080.9582861584063,
      },
    },
    cameraOut: {
      pos: {
        x: 691.411409366636,
        y: 236.49408906521035,
        z: 191.43515079933363,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 23,
    name: "Paineis Painel PSI Isover",
    pinPos: [-108.39933186573099, 222.55049112115609, 761.2338495531129],
    scale: 1.4,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 61.27692520805193,
        y: 209.02813404324115,
        z: 485.91461958107783,
      },
      target: {
        x: 60.88544120223029,
        y: 209.03378664368535,
        z: 486.83478713174134,
      },
      posMobile: {
        x: 61.27692520805193,
        y: 209.02813404324115,
        z: 485.91461958107783,
      },
      targetMobile: {
        x: 60.88544120223029,
        y: 209.03378664368535,
        z: 486.83478713174134,
      },
    },
    cameraOut: {
      pos: {
        x: 691.9101993161088,
        y: 236.89205682055373,
        z: 190.04087449279328,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 39,
    name: "impermeabilizante tecplus top quartzolit",
    pinPos: [606.0303227203605, 25.009867078423923, 579.6127032052897],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 691.0383409653336,
        y: 237.3528644698069,
        z: 189.79141923849238,
      },
      target: {
        x: 691.025715175777,
        y: 236.87894817307443,
        z: 190.67189861538287,
      },
      posMobile: {
        x: 691.0383409653336,
        y: 237.3528644698069,
        z: 189.79141923849238,
      },
      targetMobile: {
        x: 691.025715175777,
        y: 236.87894817307443,
        z: 190.67189861538287,
      },
    },
    cameraOut: {
      pos: {
        x: 691.2125299422671,
        y: 237.36119863421612,
        z: 189.71811309259084,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 45,
    name: "epóxi SF 250 quartzolit",
    pinPos: [-524.7210394189289, 35.26822521155839, 688.3276295173409],
    scale: 1.6,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -118.63738315575698,
        y: 163.10690034824455,
        z: 467.78147325074,
      },
      target: {
        x: -119.37749280994775,
        y: 162.83932497842576,
        z: 468.3984344498765,
      },
      posMobile: {
        x: -118.63738315575698,
        y: 163.10690034824455,
        z: 467.78147325074,
      },
      targetMobile: {
        x: -119.37749280994775,
        y: 162.83932497842576,
        z: 468.3984344498765,
      },
    },
    cameraOut: {
      pos: {
        x: 691.9871105214563,
        y: 236.9944652663721,
        z: 190.21029070753707,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 168,
    name: "Uretano SL Quartzolit",
    pinPos: [-130.5616218217853, 34.8465646001587, 344.9287310451],
    scale: 1.4,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 224.91060303433,
        y: 112.27347823899058,
        z: 708.6255293665662,
      },
      target: {
        x: 224.1076777750748,
        y: 112.09147793167556,
        z: 708.057914253847,
      },
      posMobile: {
        x: 224.91060303433,
        y: 112.27347823899058,
        z: 708.6255293665662,
      },
      targetMobile: {
        x: 224.1076777750748,
        y: 112.09147793167556,
        z: 708.057914253847,
      },
    },
    cameraOut: {
      pos: {
        x: 692.0199419582634,
        y: 237.10156868697888,
        z: 190.39647489617204,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 49,
    name: "contrapiso autonivelante RAD quartzolit",
    pinPos: [-0.8068718137463318, 36.08717226119582, 1318.0434126562548],
    scale: 1.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 412.0938855860412,
        y: 333.8141924804329,
        z: 895.0869524181645,
      },
      target: {
        x: 411.60355144023947,
        y: 333.35610924331985,
        z: 895.8283916761869,
      },
      posMobile: {
        x: 412.0938855860412,
        y: 333.8141924804329,
        z: 895.0869524181645,
      },
      targetMobile: {
        x: 411.60355144023947,
        y: 333.35610924331985,
        z: 895.8283916761869,
      },
    },
    cameraOut: {
      pos: {
        x: 691.6006994066097,
        y: 236.97512422157453,
        z: 189.73696027706268,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
  {
    id: 54,
    name: "Textura Acrílica quartzolit",
    pinPos: [1246.4909855916044, 246.04863329432533, 131.35621923550943],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 909.3101693921025,
        y: 18.90878439811039,
        z: 174.81900920076404,
      },
      target: {
        x: 910.0931616066658,
        y: 19.48326477629614,
        z: 174.5804814518274,
      },
      posMobile: {
        x: 909.3101693921025,
        y: 18.90878439811039,
        z: 174.81900920076404,
      },
      targetMobile: {
        x: 910.0931616066658,
        y: 19.48326477629614,
        z: 174.5804814518274,
      },
    },
    cameraOut: {
      pos: {
        x: 689.978438552261,
        y: 236.8704492937165,
        z: 190.7316177852208,
      },
      target: {
        x: 690.9704487924023,
        y: 236.85695068697729,
        z: 190.60618465135695,
      },
    },
  },
  {
    id: 20,
    name: "Feltro Facefelt Isover",
    pinPos: [-231.24578443077735, 957.2980138556164, 1136.7194342685248],
    animationSpeed: 0.1,
    scale: 2,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 198.33975978654593,
        y: 454.56463605850183,
        z: 758.6729614515931,
      },
      target: {
        x: 197.91139800704886,
        y: 455.27419739673115,
        z: 759.2324513024004,
      },
      posMobile: {
        x: 198.33975978654593,
        y: 454.56463605850183,
        z: 758.6729614515931,
      },
      targetMobile: {
        x: 197.91139800704886,
        y: 455.27419739673115,
        z: 759.2324513024004,
      },
    },
    cameraOut: {
      pos: {
        x: 691.6876460860465,
        y: 236.36315066869614,
        z: 189.9744870718556,
      },
      target: {
        x: 691.064535299161,
        y: 236.8655446003356,
        z: 190.57393121831024,
      },
    },
  },
];

export default productsIndustria;
