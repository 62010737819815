import React, { useState, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import { createBrowserHistory } from "history";
// import { getToken } from "../services/api";
import Preloader from "../shared/Components/Preloader";
import Home from "../pages/Home";
import Interna from "../pages/Interna";
import Header from "../shared/Components/Header";
import { usePreload } from "../contexts/preload";

// import { useUser } from "../contexts/user";

const history = createBrowserHistory();

const RoutesSaintGobain = () => {
  // const { user, setUser } = useUser();
  const [burgerMenuOn, setBurgerMenuOn] = useState(false);
  const [secondaryMenuOn, setSecondaryMenuOn] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const [firstView, setFirstView] = useState(false);
  const [apiToken, setApiToken] = useState(null);
  const { preload, setPreload } = usePreload();

  useEffect(() => {
    setPreload(true);
    // const sourceTrackLink = document.referrer;
    // setUser((values) => ({
    //   ...values,
    //   trackSource: sourceTrackLink,
    // }));
    // getToken()
    //   .then((res) => {
    //     setApiToken(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  useEffect(() => {
    // console.log(apiToken);
  }, [apiToken]);

  return (
    <>
      <Suspense fallback={<Preloader />}>
        <Router history={history} basename="">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {!preload && (
                    <Header
                      burgerMenuOn={burgerMenuOn}
                      setBurgerMenuOn={setBurgerMenuOn}
                      className={secondaryMenuOn ? "off" : ""}
                      isAnimating={isAnimating}
                      setIsAnimating={setIsAnimating}
                    />
                  )}
                  <Home
                    burgerMenuOn={burgerMenuOn}
                    setBurgerMenuOn={setBurgerMenuOn}
                    secondaryMenuOn={secondaryMenuOn}
                    setSecondaryMenuOn={setSecondaryMenuOn}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    firstView={firstView}
                    setFirstView={setFirstView}
                    apiToken={apiToken}
                  />
                </>
              }
            />
            <Route
              path="/:firstview"
              element={
                <>
                  <Header
                    burgerMenuOn={burgerMenuOn}
                    setBurgerMenuOn={setBurgerMenuOn}
                    className={secondaryMenuOn ? "off" : ""}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                  />
                  <Home
                    burgerMenuOn={burgerMenuOn}
                    setBurgerMenuOn={setBurgerMenuOn}
                    secondaryMenuOn={secondaryMenuOn}
                    setSecondaryMenuOn={setSecondaryMenuOn}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    firstView={firstView}
                    setFirstView={setFirstView}
                    apiToken={apiToken}
                  />
                </>
              }
            />
            <Route
              path="/in/:local"
              element={
                <Interna
                  burgerMenuOn={burgerMenuOn}
                  setBurgerMenuOn={setBurgerMenuOn}
                  secondaryMenuOn={secondaryMenuOn}
                  setSecondaryMenuOn={setSecondaryMenuOn}
                  isAnimating={isAnimating}
                  setIsAnimating={setIsAnimating}
                  firstView={firstView}
                  setFirstView={setFirstView}
                />
              }
            />
            <Route
              path="/in/:local/:product"
              element={
                <Interna
                  burgerMenuOn={burgerMenuOn}
                  setBurgerMenuOn={setBurgerMenuOn}
                  secondaryMenuOn={secondaryMenuOn}
                  setSecondaryMenuOn={setSecondaryMenuOn}
                  isAnimating={isAnimating}
                  setIsAnimating={setIsAnimating}
                  firstView={firstView}
                  setFirstView={setFirstView}
                />
              }
            />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
};

export default RoutesSaintGobain;
