const productsGalpao = [
  {
    id: 14,
    name: "macrofibra de polipropileno quartzolit",
    pinPos: [90.05047836229961, 25.391255199635587, -388.61239318347333],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -134.3228749304555,
        y: 174.73047845127235,
        z: -139.05530830091038,
      },
      target: {
        x: -133.83612205150231,
        y: 174.32780695704096,
        z: -139.83050331028818,
      },
      posMobile: {
        x: -134.3228749304555,
        y: 174.73047845127235,
        z: -139.05530830091038,
      },
      targetMobile: {
        x: -133.83612205150231,
        y: 174.32780695704096,
        z: -139.83050331028818,
      },
    },
    cameraOut: {
      pos: {
        x: 73.972575672098,
        y: 271.10580060298935,
        z: 174.9044786351133,
      },
      target: {
        x: 74,
        y: 270.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 15,
    name: "microfibra de polipropileno quartzolit",
    pinPos: [-533.3319049132452, 26.728911673863333, -446.4097939372429],
    scale: 1.2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -488.26968786479114,
        y: 264.9152292661863,
        z: 60.12778172696662,
      },
      target: {
        x: -488.52365067045304,
        y: 264.4631149160633,
        z: 59.27274049535657,
      },
      posMobile: {
        x: -488.26968786479114,
        y: 264.9152292661863,
        z: 60.12778172696662,
      },
      targetMobile: {
        x: -488.52365067045304,
        y: 264.4631149160633,
        z: 59.27274049535657,
      },
    },
    cameraOut: {
      pos: {
        x: 74.67967554988832,
        y: 270.9585092833479,
        z: 174.6786495335409,
      },
      target: {
        x: 74,
        y: 270.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 20,
    name: "Feltro Facefelt Isover",
    pinPos: [0, 616.2784510811437, -1550.981770239773],
    scale: 2.2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -197.61410238832718,
        y: 124.45916082399637,
        z: -1058.3775448158915,
      },
      target: {
        x: -197.51672382036662,
        y: 125.1402319499578,
        z: -1059.1032579381952,
      },
      posMobile: {
        x: -197.61410238832718,
        y: 124.45916082399637,
        z: -1058.3775448158915,
      },
      targetMobile: {
        x: -197.51672382036662,
        y: 125.1402319499578,
        z: -1059.1032579381952,
      },
    },
    cameraOut: {
      pos: {
        x: 74.06004425011297,
        y: 270.5313166632545,
        z: 174.98703550285958,
      },
      target: {
        x: 74,
        y: 270.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 39,
    name: "impermeabilizante tecplus top quartzolit",
    pinPos: [-249.59340574701326, 40.54842383956762, -21.682459657289478],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -68.53009309063324,
        y: 258.4737534677918,
        z: 88.9802398989525,
      },
      target: {
        x: -69.13704835618012,
        y: 257.70770902467285,
        z: 88.76862416987865,
      },
      posMobile: {
        x: -68.53009309063324,
        y: 258.4737534677918,
        z: 88.9802398989525,
      },
      targetMobile: {
        x: -69.13704835618012,
        y: 257.70770902467285,
        z: 88.76862416987865,
      },
    },
    cameraOut: {
      pos: {
        x: 74.73915929541946,
        y: 271.2213852669288,
        z: 174.4009031641013,
      },
      target: {
        x: 74,
        y: 270.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 49,
    name: "Contrapiso Autonivelante RAD Quartzolit",
    pinPos: [-613.344542133505, 75.63809409017799, 69.88330162561424],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -366.13742639208397,
        y: 214.6324991895404,
        z: 263.38735398038745,
      },
      target: {
        x: -366.93418721561284,
        y: 214.19975525769942,
        z: 262.9655662618081,
      },
      posMobile: {
        x: -366.13742639208397,
        y: 214.6324991895404,
        z: 263.38735398038745,
      },
      targetMobile: {
        x: -366.93418721561284,
        y: 214.19975525769942,
        z: 262.9655662618081,
      },
    },
    cameraOut: {
      pos: {
        x: 74.96666652355448,
        y: 270.9023373231833,
        z: 174.12725941165883,
      },
      target: {
        x: 74,
        y: 270.6801642501449,
        z: 174,
      },
    },
  },
];

export default productsGalpao;
