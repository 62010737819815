import React, { useEffect, useRef, useState, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Merged, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import coconutTree from "../../../../assets/Modelos/coco.glb";

const randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export default function CoconutTrees() {
  const { nodes } = useGLTF(coconutTree); // Substitua pelo caminho do seu modelo
  const meshRefs = useRef([]);

  // Filtra todas as meshes do modelo
  const meshes = useMemo(
    () => Object.values(nodes).filter((node) => node.isMesh),
    [nodes]
  );

  // Cria 200 instâncias com posições, rotações e escalas aleatórias
  const instances = useMemo(() => {
    return Array.from({ length: 100 }, () => ({
      color: new THREE.Color(
        `rgb(${randomBetween(50, 100)},${randomBetween(
          50,
          100
        )},${randomBetween(50, 100)})`
      ),
      scale: randomBetween(2.5, 5),
      position: [randomBetween(-700, 700), 12, randomBetween(-450, -250)],
      rotation: [
        0, // Random rotation around X
        Math.random() * Math.PI, // Random rotation around Y
        0, // Random rotation around Z
      ],
    }));
  }, []);

  useEffect(() => {
    meshes.forEach((mesh, meshIndex) => {
      const instancedMesh = meshRefs.current[meshIndex];
      if (instancedMesh) {
        instances.forEach((instance, index) => {
          const matrix = new THREE.Matrix4();
          matrix.compose(
            new THREE.Vector3(
              instance.position[0],
              instance.position[0] > -100 &&
              instance.position[0] < 100 &&
              instance.position[2] > -310
                ? -50
                : instance.position[1],
              instance.position[2]
            ),
            new THREE.Quaternion().setFromEuler(
              new THREE.Euler(...instance.rotation)
            ),
            new THREE.Vector3(instance.scale, instance.scale, instance.scale)
          );
          instancedMesh.setMatrixAt(index, matrix);
          instancedMesh.setColorAt(index, instance.color);
        });
        // instancedMesh.instanceMatrix.needsUpdate = true;
        // instancedMesh.instanceColor.needsUpdate = true;
      }
    });
  }, [meshes, instances]);

  return (
    <>
      {meshes.map((mesh, meshIndex) => (
        <instancedMesh
          ref={(el) => (meshRefs.current[meshIndex] = el)}
          key={meshIndex}
          args={[mesh.geometry, mesh.material, instances.length]}
          castShadow
        ></instancedMesh>
      ))}
    </>
  );
}
