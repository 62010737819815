import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    width:100vw;
    height:100vh;
    overflow:hidden;
    background-color: ${({ theme }) => theme.colors.sky};
    background-repeat:no-repeat;
    background-size:contain;
    color: #000000;
    ${({ theme }) => theme.fonts.body};
    tap-highlight-color: transparent;
    transition: all 0.50s linear;
  }
  .offcanvas {
    transition: transform 0.6s ease-in-out;
  }
  
  .offcanvas-end {
    width: 60vw !important;
  }
  
  @media screen and (max-width: 768px) {
    .offcanvas-end {
      width: 90vw !important;
    }
  }

  .nav-tabs{
    pointer-events:all
  }
`;
