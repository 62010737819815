/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 low_poly_city.glb -k -K -s
Author: muratbesler (https://sketchfab.com/muratbesler)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/low-poly-city-38a6b0154e584ba38fe4b2d29ed12f85
Title: low poly city
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

import model from "./low_poly_city.glb";

export function City(props) {
  const { nodes, materials } = useGLTF(model);

  const mat = new THREE.MeshStandardMaterial();
  mat.color = new THREE.Color("#ffffff");
  mat.transparent = true;
  mat.opacity = 1;
  mat.roughness = 0.5;
  mat.metalness = 0.5;
  mat.envMapIntensity = 0.3;

  return (
    <group {...props} dispose={null} renderOrder={0}>
      <group name="Sketchfab_Scene">
        <group
          name="Sketchfab_model"
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        >
          <group name="98b241ba40ed462f854a6a49e3dca488objcleanermaterialmergergles">
            <mesh
              renderOrder={0}
              name="Object_2"
              castShadow
              receiveShadow
              geometry={nodes.Object_2.geometry}
              material={mat}
            />
            <mesh
              renderOrder={0}
              name="Object_3"
              castShadow
              receiveShadow
              geometry={nodes.Object_3.geometry}
              material={mat}
            />
            <mesh
              renderOrder={0}
              name="Object_4"
              castShadow
              receiveShadow
              geometry={nodes.Object_4.geometry}
              material={mat}
            />
            <mesh
              renderOrder={0}
              name="Object_5"
              castShadow
              receiveShadow
              geometry={nodes.Object_5.geometry}
              material={mat}
            />
            <mesh
              renderOrder={0}
              name="Object_6"
              castShadow
              receiveShadow
              geometry={nodes.Object_6.geometry}
              material={mat}
            />
            <mesh
              renderOrder={0}
              name="Object_7"
              castShadow
              receiveShadow
              geometry={nodes.Object_7.geometry}
              material={mat}
            />
            <mesh
              renderOrder={0}
              name="Object_8"
              castShadow
              receiveShadow
              geometry={nodes.Object_8.geometry}
              material={mat}
            />
            <mesh
              renderOrder={0}
              name="Object_9"
              castShadow
              receiveShadow
              geometry={nodes.Object_9.geometry}
              material={mat}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(model);
