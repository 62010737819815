import React, { useRef, useEffect, useState } from "react";
import { invalidate, useFrame, useThree, useLoader } from "@react-three/fiber";
import {
  useGLTF,
  useAnimations,
  useTexture,
  Svg,
  Plane,
  Instances,
  Instance,
  BakeShadows,
} from "@react-three/drei";
import * as THREE from "three";
import gridImg from "../../images/grid.png";
import model from "./CidadeInteligente_V17_draco.glb";
import mapImg from "../../images/maps-new.png";
import Ocean from "../../../pages/Home/components/Ocean";
import CoconutTrees from "../../../pages/Home/components/CoconutTrees";
import CubesCity from "../../../pages/Home/components/CubesCity";

const randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

function Circle() {
  const texture = useLoader(THREE.TextureLoader, gridImg);
  texture.minFilter = THREE.LinearFilter;

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 8, 3416]} receiveShadow>
      <circleGeometry args={[3600, 400]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}

export default function Cidade({
  scale,
  placeHighlighted,
  setPlaceHighlighted,
  setIsAnimating,
  setData,
  location,
  setLocation,
  locationProps,
  refOrbit,
  secondaryMenuOn,
  setSecondaryMenuOn,
}) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(model, true);
  const { actions } = useAnimations(animations, group);
  const { camera } = useThree();
  const texture = useLoader(THREE.TextureLoader, gridImg);
  const textureMap = useTexture({
    map: mapImg,
  });

  useEffect(() => {}, [placeHighlighted]);

  useEffect(() => {
    Object.entries(materials).forEach(([key, value]) => {
      value.side = THREE.FrontSide;
      value.transparent = true;
      if (
        value.name === "PrediosA.001" ||
        value.name === "PrediosB.001" ||
        value.name === "PrediosC.001" ||
        value.name === "PrediosD.001" ||
        value.name === "EdComercial.001" ||
        value.name === "EdRes.001" ||
        value.name === "Hotel"
      ) {
        value.side = THREE.DoubleSide;
        value.envMapIntensity = 0.5;
        value.roughness = 0;
        value.metalness = 0;
      } else {
        value.envMapIntensity = 0.5;
        value.roughness = 0.8;
      }
      if (value.name === "Base") {
        value.emissive = new THREE.Color("#333333");
        value.roughness = 0.45;
        value.metalness = 0;
      }
      if (value.name === "Agua.002") {
        value.roughness = 0;
        value.opacity = 0.1;
        value.metalness = 0.5;
      }
    });

    Object.entries(nodes).forEach(([key, value]) => {
      if (value.type === "Mesh") {
        if (value.name !== "Base") {
          value.castShadow = true;
          value.receiveShadow = true;
        } else {
          value.castShadow = false;
          value.receiveShadow = true;
        }
      }
    });

    actions["Sphere.002Action"].loop = THREE.LoopRepeat;
    actions["Sphere.003Action"].loop = THREE.LoopRepeat;

    actions["Sphere.002Action"].play();
    actions["Sphere.003Action"].play();
  }, []);

  useFrame(() => {
    if (placeHighlighted !== null) {
      Object.entries(materials).forEach(([key, value]) => {
        if (typeof placeHighlighted === "string") {
          if (placeHighlighted === value.name) {
            if (value.opacity < 1) {
              value.opacity += 0.025;
              invalidate();
            }
          } else {
            if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            } else if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            }
          }
        } else {
          if (placeHighlighted.includes(value.name)) {
            if (value.opacity < 1) {
              value.opacity += 0.025;
              invalidate();
            }
          } else {
            if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            } else if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            }
          }
        }
      });
    } else {
      Object.entries(materials).forEach(([key, value]) => {
        if (value.opacity < 1) {
          value.opacity += 0.025;
          invalidate();
          // value.needsUpdate = true;
        }
      });
    }
  });

  const goOut = () => {
    if (location !== null) {
      let locationProp = locationProps.find((prop) => prop.id === location);
      setData((values) => ({
        ...values,
        camPosX: locationProp.cameraOut.pos.x,
        camPosY: locationProp.cameraOut.pos.y,
        camPosZ: locationProp.cameraOut.pos.z,
        targetPosX: locationProp.cameraOut.target.x,
        targetPosY: locationProp.cameraOut.target.y,
        targetPosZ: locationProp.cameraOut.target.z,
      }));
      setIsAnimating(true);
      setPlaceHighlighted(null);
      setLocation(null);
      refOrbit.current.enableRotate = true;
      refOrbit.current.enableZoom = true;
    }
  };

  return (
    <group>
      <group position={[0, 0, 70]}>
        <Circle />
        <CubesCity texture={texture} />
        <Plane
          args={[1800, 280]}
          position={[80, 8, -361]}
          rotation={[Math.PI / 2, 0, 0]}
          receiveShadow
        >
          <meshPhysicalMaterial
            color={new THREE.Color("#cccccc")}
            roughness={1}
            metalness={0}
            envMapIntensity={0}
            side={THREE.BackSide}
            sheen={2}
            sheenColor={new THREE.Color("#ffffff")}
          />
        </Plane>
        <CoconutTrees />
        <group
          ref={group}
          scale={scale}
          dispose={null}
          onClick={() => {
            goOut();
          }}
          position={[0, 8, 0]}
        >
          <group name="Scene">
            <mesh
              name="calcada"
              castShadow
              receiveShadow
              geometry={nodes.calcada.geometry}
              material={materials.Calcada}
              position={[-12.56, 10.73, -232.25]}
            />
            <mesh
              name="Base"
              castShadow
              receiveShadow
              geometry={nodes.Base.geometry}
              material={materials.Base}
            />
            <mesh
              name="Casa001"
              castShadow
              receiveShadow
              geometry={nodes.Casa001.geometry}
              material={materials["Casa.001"]}
            />
            <mesh
              name="EdComercial001"
              castShadow
              receiveShadow
              geometry={nodes.EdComercial001.geometry}
              material={materials["EdComercial.001"]}
              position={[-74.03, 56.23, 51.19]}
            />
            <mesh
              name="EdResidencial"
              castShadow
              receiveShadow
              geometry={nodes.EdResidencial.geometry}
              material={materials["EdRes.001"]}
            />
            <mesh
              name="Energia001"
              castShadow
              receiveShadow
              geometry={nodes.Energia001.geometry}
              material={materials["Energia.001"]}
            />
            <mesh
              name="Escola001"
              castShadow
              receiveShadow
              geometry={nodes.Escola001.geometry}
              material={materials["Escola.001"]}
            />
            <mesh
              name="Escritorio001"
              castShadow
              receiveShadow
              geometry={nodes.Escritorio001.geometry}
              material={materials["Escritorio.001"]}
            />
            <mesh
              name="Galpao001"
              castShadow
              receiveShadow
              geometry={nodes.Galpao001.geometry}
              material={materials["Galpao.001"]}
            />
            <mesh
              name="HeliceA001"
              castShadow
              receiveShadow
              geometry={nodes.HeliceA001.geometry}
              material={materials["Helice.001"]}
              position={[30.5, 65.56, -145.19]}
            />
            <mesh
              name="HeliceB001"
              castShadow
              receiveShadow
              geometry={nodes.HeliceB001.geometry}
              material={materials["Helice.001"]}
              position={[70.84, 65.56, -149.37]}
            />
            <mesh
              name="Hospital"
              castShadow
              receiveShadow
              geometry={nodes.Hospital.geometry}
              material={materials["Hospital.001"]}
            />
            <mesh
              name="Hotel"
              castShadow
              receiveShadow
              geometry={nodes.Hotel.geometry}
              material={materials.Hotel}
              position={[66.61, 10.86, 111.45]}
            />
            <mesh
              name="Industria"
              castShadow
              receiveShadow
              geometry={nodes.Industria.geometry}
              material={materials.Industria}
            />
            <mesh
              name="Predios_A"
              castShadow
              receiveShadow
              geometry={nodes.Predios_A.geometry}
              material={materials["PrediosA.001"]}
              position={[47.43, 10.86, 111.01]}
              scale={6.59}
            />
            <mesh
              name="Predios_B002"
              castShadow
              receiveShadow
              geometry={nodes.Predios_B002.geometry}
              material={materials["PrediosB.001"]}
            />
            <mesh
              name="Predios_C"
              castShadow
              receiveShadow
              geometry={nodes.Predios_C.geometry}
              material={materials["PrediosC.001"]}
            />
            <mesh
              name="Predios_D"
              castShadow
              receiveShadow
              geometry={nodes.Predios_D.geometry}
              material={materials["PrediosD.001"]}
            />
            <mesh
              name="PrediosBaixos001"
              castShadow
              receiveShadow
              geometry={nodes.PrediosBaixos001.geometry}
              material={materials["PrediosBaixos.001"]}
            />
            <mesh
              name="Shopping"
              castShadow
              receiveShadow
              geometry={nodes.Shopping.geometry}
              material={materials["Shopping.001"]}
              position={[31.86, 25.23, 7.59]}
            />
            <mesh
              name="Vegetacao001"
              castShadow
              receiveShadow
              geometry={nodes.Vegetacao001.geometry}
              material={materials["Vegetacao.001"]}
            />

            {/* Instanced meshes for Arbustos */}
            {nodes?.Arbusto016?.geometry &&
              materials?.["MI_Boxwood_wfypfa3ha_2K.002"] && (
                <Instances limit={17}>
                  <bufferGeometry
                    {...new THREE.BufferGeometry().copy(
                      nodes.Arbusto016.geometry
                    )}
                  />
                  <meshStandardMaterial
                    side={THREE.FrontSide}
                    {...materials["MI_Boxwood_wfypfa3ha_2K.002"]}
                    castShadow
                    receiveShadow
                    needsUpdate
                  />
                  {[
                    {
                      position: [97.23, 10.51, -267.03],
                      rotation: [0, 1.4, 0],
                      scale: [0.07, 0.09, 0.1],
                    },
                    {
                      position: [94.24, 10.51, -269.12],
                      rotation: [0, 1.51, 0],
                      scale: [0.09, 0.05, 0.06],
                    },
                    {
                      position: [77.89, 10.51, -265.41],
                      rotation: [0, 1.54, 0],
                      scale: [0.09, 0.09, 0.11],
                    },
                    {
                      position: [84.88, 10.51, -259.31],
                      rotation: [0, 1.32, 0],
                      scale: [0.07, 0.11, 0.09],
                    },
                    {
                      position: [64.51, 10.51, -247.54],
                      rotation: [0, 1.44, 0],
                      scale: [0.07, 0.1, 0.1],
                    },
                    {
                      position: [49.71, 10.51, -256.06],
                      rotation: [-Math.PI, 1.54, -Math.PI],
                      scale: [0.09, 0.1, 0.11],
                    },
                    {
                      position: [42.15, 10.51, -245.76],
                      rotation: [0, 1.47, 0],
                      scale: [0.1, 0.09, 0.12],
                    },
                    {
                      position: [69.34, 10.51, -296.18],
                      rotation: [0, 1.49, 0],
                      scale: [0.08, 0.09, 0.09],
                    },
                    {
                      position: [54.54, 10.51, -298.85],
                      rotation: [-Math.PI, 1.5, -Math.PI],
                      scale: [0.07, 0.07, 0.07],
                    },
                    {
                      position: [83.11, 10.51, -277.75],
                      rotation: [-Math.PI, 1.53, -Math.PI],
                      scale: [0.04, 0.05, 0.04],
                    },
                    {
                      position: [29.77, 10.51, -253.99],
                      rotation: [-Math.PI, 1.48, -Math.PI],
                      scale: [0.07, 0.08, 0.11],
                    },
                    {
                      position: [23.03, 10.51, -237.29],
                      rotation: [0, 1.25, 0],
                      scale: [0.1, 0.1, 0.07],
                    },
                    {
                      position: [8.42, 10.51, -241.23],
                      rotation: [0, 1.48, 0],
                      scale: [0.13, 0.15, 0.16],
                    },
                    {
                      position: [89.06, 10.51, -247.54],
                      rotation: [0, 1.38, 0],
                      scale: [0.06, 0.11, 0.11],
                    },
                    {
                      position: [90.01, 10.51, -277.51],
                      rotation: [-Math.PI, 1.47, -Math.PI],
                      scale: [0.1, 0.09, 0.08],
                    },
                    {
                      position: [78.58, 10.51, -242.09],
                      rotation: [0, 1.45, 0],
                      scale: [0.11, 0.06, 0.11],
                    },
                    {
                      position: [94.09, 10.51, -238.96],
                      rotation: [0, 1.27, 0],
                      scale: [0.08, 0.11, 0.06],
                    },
                  ].map((props, index) => (
                    <Instance
                      key={`arbusto_${index}`}
                      {...props}
                      castShadow
                      receiveShadow
                    />
                  ))}
                </Instances>
              )}

            {/* Instanced meshes for Cerca */}
            {nodes?.Mesh002?.geometry && materials?.Metal && (
              <Instances limit={100}>
                <bufferGeometry
                  {...new THREE.BufferGeometry().copy(nodes.Mesh002.geometry)}
                />
                <meshStandardMaterial {...materials.Metal} />
                <Instance
                  key="cerca_0"
                  position={[97.67, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_1"
                  position={[93.1, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_2"
                  position={[88.53, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_3"
                  position={[83.96, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_4"
                  position={[79.39, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_5"
                  position={[74.82, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_6"
                  position={[70.25, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_7"
                  position={[65.68, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_8"
                  position={[61.11, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_9"
                  position={[56.54, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_10"
                  position={[51.97, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_11"
                  position={[47.4, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_12"
                  position={[42.83, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_13"
                  position={[38.25, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_14"
                  position={[33.68, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_15"
                  position={[29.11, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_16"
                  position={[24.54, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_17"
                  position={[19.97, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_18"
                  position={[15.4, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_19"
                  position={[10.83, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_20"
                  position={[6.26, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_21"
                  position={[1.69, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_22"
                  position={[-2.88, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_23"
                  position={[-7.45, 12.49, -232.92]}
                  rotation={[Math.PI, 0, Math.PI]}
                />
                <Instance
                  key="cerca_24"
                  position={[-9.73, 12.49, -235.24]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_25"
                  position={[-9.73, 12.49, -239.81]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_26"
                  position={[-9.73, 12.49, -244.37]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_27"
                  position={[-9.73, 12.49, -248.94]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_28"
                  position={[-9.73, 12.49, -253.52]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_29"
                  position={[-9.73, 12.49, -258.09]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_30"
                  position={[-9.73, 12.49, -262.65]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_31"
                  position={[-9.73, 12.49, -267.22]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_32"
                  position={[-9.73, 12.49, -271.8]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_33"
                  position={[-9.73, 12.49, -276.37]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_34"
                  position={[-9.73, 12.49, -280.93]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_35"
                  position={[-9.73, 12.49, -285.5]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_36"
                  position={[-9.73, 12.49, -290.08]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_37"
                  position={[-9.73, 12.49, -294.65]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_38"
                  position={[-9.73, 12.49, -299.21]}
                  rotation={[0, Math.PI / 2, 0]}
                />
                <Instance
                  key="cerca_39"
                  position={[-7.41, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_40"
                  position={[-2.84, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_41"
                  position={[1.73, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_42"
                  position={[6.3, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_43"
                  position={[10.87, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_44"
                  position={[15.44, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_45"
                  position={[20.01, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_46"
                  position={[24.58, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_47"
                  position={[29.15, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_48"
                  position={[33.72, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_49"
                  position={[38.29, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_50"
                  position={[42.86, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_51"
                  position={[47.44, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_52"
                  position={[52, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_53"
                  position={[56.57, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_54"
                  position={[61.15, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_55"
                  position={[65.72, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_56"
                  position={[70.29, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_57"
                  position={[74.86, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_58"
                  position={[79.43, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_59"
                  position={[84, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_60"
                  position={[88.57, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_61"
                  position={[93.14, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
                <Instance
                  key="cerca_62"
                  position={[97.71, 12.49, -301.46]}
                  rotation={[0, 0, 0]}
                />
              </Instances>
            )}
            <mesh
              name="Silo"
              castShadow
              receiveShadow
              geometry={nodes.Silo.geometry}
              material={materials.Silo}
              position={[71.76, 11.06, -257.92]}
            />
            <mesh
              name="Piso_Concreto"
              castShadow
              receiveShadow
              geometry={nodes.Piso_Concreto.geometry}
              material={materials.PisoConcreto}
              position={[35.56, 10.67, -279.27]}
            />
            <mesh
              name="Bosque"
              castShadow
              receiveShadow
              geometry={nodes.Bosque.geometry}
              material={materials.Bosque}
              position={[54.2, 5.32, -202.43]}
            />
            <mesh
              name="Canal"
              castShadow
              receiveShadow
              geometry={nodes.Canal.geometry}
              material={materials.Canal}
              position={[54.2, 5.32, -202.43]}
              material-roughness={0}
              material-metalness={0}
            />
            <mesh
              name="Ponte"
              castShadow
              receiveShadow
              geometry={nodes.Ponte.geometry}
              material={materials.Ponte}
              position={[-18.85, -1.14, -203.2]}
            />

            <Ocean position={[0, -0.1, 0]} />

            <mesh
              name="TerrenoAviario"
              castShadow
              receiveShadow
              geometry={nodes.TerrenoAviario.geometry}
              material={materials.TerrenoAviario}
              position={[58.02, 10.67, -282.75]}
              material-roughness={0}
              material-metalness={0}
              material-envMapIntensity={0}
            />
            <mesh
              name="Grama_C"
              castShadow
              receiveShadow
              geometry={nodes.Grama_C.geometry}
              material={materials.Grama_C}
              position={[84.21, 10.67, -292.35]}
              material-roughness={0}
              material-metalness={0}
              material-envMapIntensity={0}
            />
            <mesh
              name="Grama_B"
              castShadow
              receiveShadow
              geometry={nodes.Grama_B.geometry}
              material={materials.Grama_B}
              position={[84.21, 10.67, -273.58]}
              material-roughness={0}
              material-metalness={0}
              material-envMapIntensity={0}
            />
            <mesh
              name="Grama_A"
              castShadow
              receiveShadow
              geometry={nodes.Grama_A.geometry}
              material={materials.Grama_A}
              position={[84.21, 10.67, -254.81]}
              material-roughness={0}
              material-metalness={0}
              material-envMapIntensity={0}
            />
            <mesh
              name="Arvores"
              castShadow
              receiveShadow
              geometry={nodes.Arvores.geometry}
              material={materials.Arvores}
              position={[-63, 16.29, -259.25]}
            />
            <mesh
              name="Door"
              castShadow
              receiveShadow
              geometry={nodes.Door.geometry}
              material={materials.Door}
              position={[36.51, 13.51, -279.56]}
              rotation={[0, Math.PI / 2, 0]}
            >
              <group
                name="calha001"
                position={[0, 2.74, 30.01]}
                rotation={[0, -Math.PI / 2, 0]}
              >
                <mesh
                  name="Cube016"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube016.geometry}
                  material={materials["Metal galvanizado.002"]}
                />
                <mesh
                  name="Cube016_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube016_1.geometry}
                  material={materials["Metal liso.002"]}
                />
              </group>
              <mesh
                name="TelhaTopComfort001"
                castShadow
                receiveShadow
                geometry={nodes.TelhaTopComfort001.geometry}
                material={materials.TelhaTopComfort}
                position={[0.01, 2.84, -0.15]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                name="ventilador034"
                position={[1.13, -1.85, -30.02]}
                rotation={[Math.PI, 0, Math.PI]}
              >
                <mesh
                  name="Cube013"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube013.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube013_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube013_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador035"
                position={[-1, -1.85, -30.02]}
                rotation={[Math.PI, 0, Math.PI]}
              >
                <mesh
                  name="Cube013"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube013.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube013_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube013_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador036"
                position={[-3.13, -1.85, -30.02]}
                rotation={[Math.PI, 0, Math.PI]}
              >
                <mesh
                  name="Cube013"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube013.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube013_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube013_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador037"
                position={[-11.98, -1.85, -26.64]}
                rotation={[0, -Math.PI / 2, 0]}
              >
                <mesh
                  name="Cube014"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube014.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube014_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube014_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador038"
                position={[-11.98, -1.85, -24.51]}
                rotation={[0, -Math.PI / 2, 0]}
              >
                <mesh
                  name="Cube014"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube014.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube014_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube014_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador039"
                position={[-11.98, -1.85, -22.38]}
                rotation={[0, -Math.PI / 2, 0]}
              >
                <mesh
                  name="Cube014"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube014.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube014_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube014_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador040"
                position={[11.75, -1.85, -24.24]}
                rotation={[0, 1.57, 0]}
              >
                <mesh
                  name="Cube015"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube015.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube015_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube015_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador041"
                position={[11.75, -1.85, -26.36]}
                rotation={[0, 1.57, 0]}
              >
                <mesh
                  name="Cube015"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube015.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube015_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube015_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
              <group
                name="ventilador042"
                position={[11.75, -1.85, -28.49]}
                rotation={[0, 1.57, 0]}
              >
                <mesh
                  name="Cube015"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube015.geometry}
                  material={materials["Ventilador .002"]}
                />
                <mesh
                  name="Cube015_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube015_1.geometry}
                  material={materials["Nulo.002"]}
                />
              </group>
            </mesh>
            <mesh
              name="WallBoard"
              castShadow
              receiveShadow
              geometry={nodes.WallBoard.geometry}
              material={materials.Wallboard}
              position={[36.51, 13.51, -279.56]}
              rotation={[0, Math.PI / 2, 0]}
            />
            <mesh
              name="Pedras"
              castShadow
              receiveShadow
              geometry={nodes.Pedras.geometry}
              material={materials.Pedras}
              position={[36.51, 13.51, -279.56]}
              rotation={[0, Math.PI / 2, 0]}
            />
            <mesh
              name="Estrutura"
              castShadow
              receiveShadow
              geometry={nodes.Estrutura.geometry}
              material={materials["Metal.001"]}
              position={[36.51, 13.51, -279.56]}
              rotation={[0, Math.PI / 2, 0]}
            />
            <BakeShadows />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(model);
