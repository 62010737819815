export const configData = {
  axis: true,
  animationSpeed: 1,
  entryPos: {
    "edificio-residencial": {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    escola: {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    escritorio: {
      camPosX: 73.65370867037373,
      camPosY: 230.7714702122137,
      camPosZ: 174.93367314212009,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    galpao: {
      camPosX: 84.50369154431179,
      camPosY: 270.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 270.6801642501449,
      targetPosZ: 174,
    },
    hotel: {
      camPosX: 74.98397116096913,
      camPosY: 230.85832066632062,
      camPosZ: 174.0078131783857,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    hospital: {
      camPosX: 66.84419961316635,
      camPosY: 233.681129627419,
      camPosZ: 157.035921087495,
      targetPosX: 65.87810874024639,
      targetPosY: 233.58089828382273,
      targetPosZ: 157.27367126602616,
    },
    industria: {
      camPosX: 691.4581797410372,
      camPosY: 236.9025693313623,
      camPosZ: 189.6552316214168,
      targetPosX: 691.064535299161,
      targetPosY: 236.8655446003356,
      targetPosZ: 190.57393121831024,
    },
    infraestrutura: {
      camPosX: 92.46328278723215,
      camPosY: 281.33578294077205,
      camPosZ: 191.6508322307582,
      targetPosX: 91.46402199409837,
      targetPosY: 281.36687230308087,
      targetPosZ: 191.62821987004793,
    },
    "residencial-unifamiliar": {
      camPosX: 522.1002668421193,
      camPosY: 234.38618110799536,
      camPosZ: -587.3076461009182,
      targetPosX: 521.5026932288724,
      targetPosY: 234.08996335939108,
      targetPosZ: -586.5625549506802,
    },
    shopping: {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    "parque-eolico": {
      camPosX: 74.70787716173704,
      camPosY: 230.44368492961672,
      camPosZ: 173.33442697256152,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    ponte: {
      camPosX: -1571.70787716173704,
      camPosY: 1380.44368492961672,
      camPosZ: -6877.33442697256152,
      targetPosX: -1554,
      targetPosY: 1360.6801642501449,
      targetPosZ: -6860,
    },
    aviario: {
      camPosX: 2374.70787716173704,
      camPosY: 280.44368492961672,
      camPosZ: -8573.33442697256152,
      targetPosX: 2359,
      targetPosY: 276.6801642501449,
      targetPosZ: -8558,
    },
  },
  near: 1,
  far: 20000,
  fov: 60,
  zoom: 1,
  focusDistance: 0.092,
  focalLength: 1.0,
  bokehScale: 3.68,
  height: 1080,
  targetVisible: true,

  minPolarAngle: Math.PI / 4.5,
  maxPolarAngle: Math.PI,
  minDistance: 1,
  maxDistance: 1000,
  enableZoom: true,
  enablePan: false,
  enableRotate: true,
  rotateSpeed: 0.18,
  autoRotate: false,
  enableDamping: true,
  dampingFactor: 0.1,
  backgroundColor: "#03D0EC",
  fog: false,
  fogColor: "#03D0EC",
  fogNear: 600,
  fogFar: 850,
  menuOpacity: 0,
};

export const configDataMobile = {
  axis: true,
  animationSpeed: 1,
  entryPos: {
    "edificio-residencial": {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    escola: {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    escritorio: {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    galpao: {
      camPosX: 84.50369154431179,
      camPosY: 270.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 270.6801642501449,
      targetPosZ: 174,
    },
    hotel: {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    hospital: {
      camPosX: 66.84419961316635,
      camPosY: 233.681129627419,
      camPosZ: 157.035921087495,
      targetPosX: 65.87810874024639,
      targetPosY: 233.58089828382273,
      targetPosZ: 157.27367126602616,
    },
    industria: {
      camPosX: 691.4581797410372,
      camPosY: 236.9025693313623,
      camPosZ: 189.6552316214168,
      targetPosX: 691.064535299161,
      targetPosY: 236.8655446003356,
      targetPosZ: 190.57393121831024,
    },
    infraestrutura: {
      camPosX: 90.46702830249284,
      camPosY: 281.44431592874344,
      camPosZ: 191.62575740319585,
      targetPosX: 91.46402199409837,
      targetPosY: 281.36687230308087,
      targetPosZ: 191.62821987004793,
    },
    "residencial-unifamiliar": {
      camPosX: 186.19507964856285,
      camPosY: 205.49879665873658,
      camPosZ: 10.107617413478,
      targetPosX: 186.46661909277898,
      targetPosY: 205.43259886346874,
      targetPosZ: 9.147469413356937,
    },
    shopping: {
      camPosX: 84.50369154431179,
      camPosY: 230.68183835456134,
      camPosZ: 214.4330955821151,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
    "parque-eolico": {
      camPosX: 0,
      camPosY: 230.68183835456134,
      camPosZ: 0,
      targetPosX: 74,
      targetPosY: 230.6801642501449,
      targetPosZ: 174,
    },
  },
  near: 0.1,
  far: 1000,
  fov: 60,
  zoom: 1,
  focusDistance: 0.092,
  focalLength: 1.0,
  bokehScale: 3.68,
  height: 1080,
  targetVisible: true,
  targetPosX: 0,
  targetPosY: 220,
  targetPosZ: 0,
  minPolarAngle: Math.PI / 4.5,
  maxPolarAngle: Math.PI,
  minDistance: 1,
  maxDistance: 1000,
  enableZoom: true,
  enablePan: false,
  enableRotate: true,
  rotateSpeed: 0.18,
  autoRotate: false,
  enableDamping: true,
  dampingFactor: 0.1,
  backgroundColor: "#03D0EC",
  fog: false,
  fogColor: "#03D0EC",
  fogNear: 600,
  fogFar: 850,
  menuOpacity: 0,
};

export const locationsOut = [
  {
    id: 4,
    name: "Hotel",
    materialId: "Hotel",
    thumb: `/assets/images/hotel.png`,
    description: "Descrição do Hotel",
    pinPos: [67, 60, 111.5],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 250.83015119058166,
        y: 277.05496496626574,
        z: -30.961773584343234,
      },
      target: {
        x: -17.94703763919932,
        y: -5.906528277109161,
        z: 178.72247913353877,
      },
      posMobile: {
        x: 350.83015119058166,
        y: 277.05496496626574,
        z: -110.961773584343234,
      },
      targetMobile: {
        x: -17.94703763919932,
        y: -5.906528277109161,
        z: 178.72247913353877,
      },
    },
    cameraOut: {
      pos: {
        x: 359.34204551447453,
        y: 245.65357166206152,
        z: -236.80639784880282,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 3,
    name: "Hospital",
    materialId: "Hospital",
    thumb: `/assets/images/hospital.png`,
    description: "Descrição do Hospital",
    pinPos: [40, 25, 62],
    boardPos: [-46, 72, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 196.8283294723,
        y: 247.69324474745406,
        z: -104.37646305621132,
      },
      target: {
        x: -8.792162443718228,
        y: -15.002948536249107,
        z: 116.32904964899052,
      },
      posMobile: {
        x: 296.8283294723,
        y: 247.69324474745406,
        z: -220.37646305621132,
      },
      targetMobile: {
        x: -8.792162443718228,
        y: -15.002948536249107,
        z: 116.32904964899052,
      },
    },
    cameraOut: {
      pos: {
        x: 409.5915888725715,
        y: 282.1833749609159,
        z: 95.79036969117942,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 7,
    name: "Parque Eólico",
    materialId: "Helice",
    thumb: `/assets/images/edres.png`,
    description: "Descrição Energia Eólica",
    pinPos: [50, 90, -148.5],
    boardPos: [-39, 60, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 199.48385433775803,
        y: 356.8620281745439,
        z: -357.3206815456765,
      },
      target: {
        x: 1.4700660028794623,
        y: 17.708187017543388,
        z: -74.50111778876331,
      },
      posMobile: {
        x: 209.59537904691643,
        y: 220.1386314516232,
        z: -558.089805765027,
      },
      targetMobile: {
        x: -7.319350602121656,
        y: 46.2936373554452,
        z: 0,
      },
    },
    cameraOut: {
      pos: {
        x: 322.8775816760988,
        y: 309.4317835017294,
        z: 319.39101969793995,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 1,
    name: "Edifício Residencial",
    materialId: "EdComercial",
    thumb: `/assets/images/edcom.png`,
    description: "Descrição do Prédio",
    pinPos: [-74.5, 90, 51],
    boardPos: [-37, 55, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -196.17572740633753,
        y: 323.6180716807185,
        z: -118.08943159569947,
      },
      target: {
        x: -5.586692306986576,
        y: -12.860789448637826,
        z: 142.9041668563675,
      },
      posMobile: {
        x: -326.17572740633753,
        y: 323.6180716807185,
        z: -285.08943159569947,
      },
      targetMobile: {
        x: -5.586692306986576,
        y: -12.860789448637826,
        z: 142.9041668563675,
      },
    },
    cameraOut: {
      pos: {
        x: -232.10465932628063,
        y: 266.9618146093414,
        z: 423.2362039106099,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 9,
    name: "Galpão",
    materialId: "Galpao",
    thumb: `/assets/images/galpao.png`,
    description: "Descrição do Galpao",
    pinPos: [-53, 20, -53],
    boardPos: [-48, 78, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -222.15186428861938,
        y: 243.99205079342073,
        z: 119.35086058348371,
      },
      target: {
        x: -1.0107200960154679,
        y: -1.326583607577808,
        z: -106.29346564878647,
      },
      posMobile: {
        x: -322.15186428861938,
        y: 243.99205079342073,
        z: 225.35086058348371,
      },
      targetMobile: {
        x: -1.0107200960154679,
        y: -1.326583607577808,
        z: -106.29346564878647,
      },
    },
    cameraOut: {
      pos: {
        x: -368.13871153653344,
        y: 305.52859358010045,
        z: 260.94900172100625,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 8,
    name: "Escola",
    materialId: "Escola",
    thumb: `/assets/images/escola.png`,
    description: "Descrição da Escola",
    pinPos: [-72, 25, 0],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -243.39490455224063,
        y: 213.47189671724072,
        z: 183.2152708234283,
      },
      target: {
        x: -15.572147290330356,
        y: -7.329364222358532,
        z: -60.390136425802744,
      },
      posMobile: {
        x: -343.39490455224063,
        y: 213.47189671724072,
        z: 290.2152708234283,
      },
      targetMobile: {
        x: -15.572147290330356,
        y: -7.329364222358532,
        z: -60.390136425802744,
      },
    },
    cameraOut: {
      pos: {
        x: -367.72637319839043,
        y: 305.1751724093552,
        z: 260.6567145003765,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 10,
    name: "Residencial Unifamiliar",
    materialId: "Casa",
    thumb: `/assets/images/casa.jpg`,
    description: "Descrição da Casa",
    pinPos: [-30, 20, 3],
    boardPos: [-46, 76, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 150.48952324663819,
        y: 264.7091757944077,
        z: 142.84740891775405,
      },
      target: {
        x: -78.20888730056814,
        y: -13.93912188500148,
        z: -30.508188665793334,
      },
      posMobile: {
        x: 250.48952324663819,
        y: 264.7091757944077,
        z: 200.84740891775405,
      },
      targetMobile: {
        x: -78.20888730056814,
        y: -13.93912188500148,
        z: -30.508188665793334,
      },
    },
    cameraOut: {
      pos: {
        x: 384.7376734644313,
        y: 306.8323577819247,
        z: 153.8338769708527,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 11,
    name: "Shopping",
    materialId: "Shopping",
    thumb: `/assets/images/shopping.png`,
    description: "Descrição do Shopping",
    pinPos: [48, 30, 3],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 243.69179926601998,
        y: 240.52052964520232,
        z: 151.62262191242462,
      },
      target: {
        x: -13.782475299567189,
        y: -6.285279567438856,
        z: -46.295447950633545,
      },
      posMobile: {
        x: 343.69179926601998,
        y: 240.52052964520232,
        z: 239.62262191242462,
      },
      targetMobile: {
        x: -13.782475299567189,
        y: -6.285279567438856,
        z: -46.295447950633545,
      },
    },
    cameraOut: {
      pos: {
        x: 469.5791897838509,
        y: 265.0000000000004,
        z: 81.5428282599478,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 2,
    name: "Escritório",
    materialId: "Escritorio",
    thumb: `/assets/images/escritorio.png`,
    description: "Descrição do Escritório",
    pinPos: [67, 24, -47],
    boardPos: [-46, 74, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 290.3942019812768,
        y: 213.21694329538755,
        z: 54.42186507543899,
      },
      target: {
        x: -16.990594722203664,
        y: -2.876745478836506,
        z: -82.75723109999423,
      },
      posMobile: {
        x: 420.3942019812768,
        y: 213.21694329538755,
        z: 100.42186507543899,
      },
      targetMobile: {
        x: -16.990594722203664,
        y: -2.876745478836506,
        z: -82.75723109999423,
      },
    },
    cameraOut: {
      pos: {
        x: 411.0198552005528,
        y: 240.549408639877,
        z: -125.91181080290448,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 5,
    name: "Indústria",
    materialId: "Industria",
    thumb: `/assets/images/industria.png`,
    description: "Descrição da Ind",
    pinPos: [-45, 24, -127],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -231.31967510390098,
        y: 249.13043185379846,
        z: 13.878567054585545,
      },
      target: {
        x: 13.884145655978493,
        y: -10.95252368083359,
        z: -171.61918122028013,
      },
      posMobile: {
        x: -331.31967510390098,
        y: 249.13043185379846,
        z: 93.878567054585545,
      },
      targetMobile: {
        x: 13.884145655978493,
        y: -10.95252368083359,
        z: -171.61918122028013,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 260.8614219945696,
        z: -225.241935672166,
      },
      target: [0, 10, 0],
    },
    products: [],
  },
  {
    id: 6,
    name: "Infraestrutura",
    materialId: "Ind",
    thumb: `/assets/images/tunel.jpg`,
    description: "Descrição da Infraestrutura",
    pinPos: [-22, 24, -93],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -231.31967510390098,
        y: 249.13043185379846,
        z: 13.878567054585545,
      },
      target: {
        x: 13.884145655978493,
        y: -10.95252368083359,
        z: -171.61918122028013,
      },
      posMobile: {
        x: -201.31967510390098,
        y: 249.13043185379846,
        z: 93.878567054585545,
      },
      targetMobile: {
        x: 35.884145655978493,
        y: 20.95252368083359,
        z: -151.61918122028013,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 12,
    name: "Aviário",
    materialId: "Aviario predio.001",
    thumb: `/assets/images/tunel.jpg`,
    description: "Descrição do Aviário",
    pinPos: [38, 35, -208],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 139.32208853685134,
        y: 168.83729433359485,
        z: -513.0295860297775,
      },
      target: {
        x: -29.712145177105942,
        y: -1.1357101056193517,
        z: -17.968757521119922,
      },
      posMobile: {
        x: 105.00483292847626,
        y: 218.76022379523226,
        z: -603.0171159338682,
      },
      targetMobile: {
        x: -0.008020484931703589,
        y: 19.909583177798034,
        z: -0.09465289895813647,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 13,
    name: "Ponte",
    materialId: "ponte.001",
    thumb: `/assets/images/tunel.jpg`,
    description: "Descrição da Ponte",
    pinPos: [-18, 60, -135],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -509.19829185450726,
        y: 249.72837662967362,
        z: 101.07809615298756,
      },
      target: {
        x: -35.834791945042156,
        y: 79.76902972345253,
        z: -121.49956973618607,
      },
      posMobile: {
        x: -64.9878571388337,
        y: 230.57378072637815,
        z: -475.2262528708368,
      },
      targetMobile: {
        x: 0.02246623073740958,
        y: 20.00058471752712,
        z: -0.09494183094360345,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
];
