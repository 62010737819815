import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  MenuHolder,
  Menu,
  MenuLines,
  MenuLine,
  MenuItems,
  MenuItem,
  OffShort,
  OffShortTwoColumns,
  OffShortOneColumn,
  OffFullBanner,
  Assinatura,
  OffShortTitle,
  OffShortContent0,
  OffShortContent,
  OffShortContent2,
  Link,
  OffShortLogos,
  OffShortLogo,
  OffFull,
  OffFullTitle,
  OffFullThree,
  OffFullThreeItem,
  OffFullThreeItemTitle,
  OffFullThreeItemContent,
  OffFullThreeItemLink,
  OffFullTwo,
  OffFullTwoItem,
  OffFullTwoItemLogo,
  OffFullTwoItemImagem,
  OffFullTwoItemLink,
  OffFullTwoItemTitle,
  OffFullTwoItemWhatsapp,
  OffFullTwoItemEmail,
} from "./style";
import logoSaintGobainHorizontal from "../../../../assets/images/logo-cidade-inteligente-new-horizontal.png";
import logoConstruirOAmanha from "../../../../assets/images/logos/construir-o-amanha.png";
import makeTheWorld from "../../../../assets/images/maketheworld.png";
import logos2023 from "../../../../assets/images/logos-saintgobain-2023.png";

const BurgerMenu = ({ burgerMenuOn, setBurgerMenuOn }) => {
  const menuRef = useRef(null);
  let navigate = useNavigate();

  const checkMenu = (e) => {
    let checked = !menuRef.current.checked;
    setBurgerMenuOn(checked);
    menuRef.current.checked = checked;
  };

  const goTo = (section) => {
    navigate(section);
    menuRef.current.checked = false;
    setBurgerMenuOn(false);
  };

  const sharePage = () => {};

  return (
    <MenuHolder onClick={checkMenu} className={isMobile && "mobile"}>
      <Menu ref={menuRef} />
      <MenuLines className={`${burgerMenuOn && "on"} ${isMobile && "mobile"}`}>
        <MenuLine />
        <MenuLine />
        <MenuLine />
      </MenuLines>
      <Offcanvas show={burgerMenuOn} placement={isMobile ? "bottom" : "end"}>
        <Offcanvas.Body style={{ backgroundColor: "#e5e5e5", padding: "0px" }}>
          <OffShort>
            <OffFullBanner>
              <img src={logoSaintGobainHorizontal} />
              <Assinatura>
                Construindo o seu mundo com inovação e sustentabilidade
              </Assinatura>
            </OffFullBanner>
            <OffShortOneColumn>
              <OffShortContent0>
                As melhores soluções para construção a seco; fachadas leves;
                reparo, reforço, proteção e impermeabilização de obras; absorção
                acústica; isolamento termoacústico e muitos outros sistemas.
                <br />
                <br />A <b>Cidade Inteligente Saint-Gobain</b> apresenta de
                forma interativa o portfólio completo da Saint-Gobain para os
                mais diversos espaços, como residências, escritórios, escolas,
                hospitais, hotéis, shopping centers, galpões, indústrias e obras
                de infraestrutura.
                <br />
                <br />
                <div>
                  Nossa cidade foi especialmente planejada para facilitar a
                  consulta dos profissionais da construção aos sistemas de
                  produtos técnicos mais completos do mercado. Ela reúne
                  produtos, aplicações, dados e vantagens apresentadas de forma
                  integrada de cada projeto.
                  <br />
                  As cidades inteligentes não são apenas um conceito do futuro.
                  A Cidade Inteligente by Saint-Gobain está aqui, ao alcance de
                  quem busca por inovação, alta performance e durabilidade para
                  construir espaços melhores para se viver com segurança,
                  qualidade e bem-estar.
                </div>
              </OffShortContent0>
              <OffShortLogos>
                <img src={logos2023} />
              </OffShortLogos>
            </OffShortOneColumn>
            <OffShortTwoColumns>
              <OffShortContent>
                <img src={logoConstruirOAmanha} />
              </OffShortContent>
              <OffShortContent2>
                Cidades inteligentes são aquelas que sabem utilizar os melhores
                recursos para se desenvolver e gerar mais qualidade de vida aos
                habitantes, sem abrir mão dos cuidados com o futuro. Por isso,
                #ConstruirOAmanhã é com a gente, é com a nossa Cidade
                Inteligente. A Saint-Gobain projeta, fabrica e distribui
                produtos e sistemas completos para a construção leve e
                sustentável. Nossas soluções inovadoras nos ajudam a cumprir o
                nosso propósito: making the world a better home, que reflete
                exatamente o nosso compromisso com a sustentabilidade e com o
                que vem pela frente. Cuidamos do mundo em que vivemos, das
                pessoas e sociedade com as quais interagimos da forma como
                somos.
                <Link href="https://construiroamanha.com.br">
                  construiroamanha.com.br
                </Link>
              </OffShortContent2>
            </OffShortTwoColumns>
          </OffShort>
          <OffShort>
            <OffShortTwoColumns>
              <OffShortContent>
                <img src={makeTheWorld} width="60%" />
              </OffShortContent>
              <OffShortContent2 className="noPadding">
                <OffShortTitle>
                  Saint-Gobain Produtos para Construção
                </OffShortTitle>
                Fundada na França em 1665, a Saint-Gobain é líder mundial em
                soluções para obras de construção civil, desde a fundação até as
                telhas. No Brasil, a divisão Saint-Gobain Produtos para
                Construção reúne em seu portifólio seis marcas - Quartzolit,
                Brasilit, Placo, Isover e Ecophon - que fornecem soluções
                completas para todas as necessidades construtivas, da fundação
                ao telhado, com a missão de seguir inovando e oferecendo
                produtos completos e integrados em um dos mercados de maior
                relevância do país.
                <Link href="https://saint-gobain.com.br">
                  saint-gobain.com.br
                </Link>
              </OffShortContent2>
            </OffShortTwoColumns>
          </OffShort>
          <OffFull>
            <OffFullThree>
              <OffFullThreeItem>
                <OffFullThreeItemTitle>Sistemas</OffFullThreeItemTitle>
                <OffFullThreeItemContent>Em breve</OffFullThreeItemContent>
                {/* <OffFullThreeItemLink
                  href="https://saint-gobain.com.br"
                  target="_blank"
                >
                  Ir para Sistemas
                </OffFullThreeItemLink> */}
              </OffFullThreeItem>
              <OffFullThreeItem>
                <OffFullThreeItemTitle>Cases</OffFullThreeItemTitle>
                <OffFullThreeItemContent>Em breve</OffFullThreeItemContent>
                {/* <OffFullThreeItemLink
                  href="https://saint-gobain.com.br"
                  target="_blank"
                >
                  Ir para Cases
                </OffFullThreeItemLink> */}
              </OffFullThreeItem>
              <OffFullThreeItem>
                <OffFullThreeItemTitle>Blog</OffFullThreeItemTitle>
                <OffFullThreeItemContent>Em breve</OffFullThreeItemContent>
                {/* <OffFullThreeItemLink
                  href="https://saint-gobain.com.br"
                  target="_blank"
                >
                  Ir para Blog
                </OffFullThreeItemLink> */}
              </OffFullThreeItem>
            </OffFullThree>
          </OffFull>
        </Offcanvas.Body>
      </Offcanvas>
    </MenuHolder>
  );
};

export default BurgerMenu;
