import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { useHelper } from "@react-three/drei";

const DirectionalLight = (props) => {
  const targetObject = new THREE.Object3D();
  const { scene, gl } = useThree();
  const lightRef = useRef();
  const [isReady, setIsReady] = useState(false);

  targetObject.position.set(
    props.targetPos[0],
    props.targetPos[1],
    props.targetPos[2]
  );
  scene.add(targetObject);

  // useHelper(lightRef, THREE.DirectionalLightHelper, 1, 0xff0000);

  useEffect(() => {
    gl.getContext().canvas.addEventListener(
      "selectstart",
      function (e) {
        e.preventDefault();
        return false;
      },
      false
    );
  }, []);

  return (
    <>
      <directionalLight
        ref={lightRef}
        position={props.pos}
        color={0xffffff}
        intensity={props.intensity}
        // target={targetObject}
        castShadow={true}
        shadow-mapSize={[1024, 1024]}
        shadow-camera-near={1}
        shadow-camera-far={10000}
        shadow-camera-top={-1000}
        shadow-camera-bottom={1000}
        shadow-camera-left={-1000}
        shadow-camera-right={1000}
        shadow-bias={-0.0003}
      />
      {/* {lightRef.current && (
        <directionalLightHelper args={[lightRef.current, 2, 0xff0000]} />
      )} */}
    </>
  );
};

export default DirectionalLight;
