import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dompurify from "dompurify";
import { Offcanvas, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useScrollContainer } from "react-indiana-drag-scroll";
import { useTheme } from "styled-components";
import CloseInterna from "../Header/CloseInterna";
import {
  AllBlocks,
  LeftBlock,
  RightBlock,
  FirstBlock,
  FirstBlockTop,
  FirstBlockTopRight,
  FirstBlockImage,
  FirstBlockMenu,
  FirstBlockTopRightText,
  FirstBlockTitle,
  FirstBlockText,
  SecondBlock,
  ThirdBlock,
  FirstBlockRight,
  FirstBlockRightTitle,
  FirstBlockRightThumbs,
  FirstBlockRightThumbContainer,
  FirstBlockRightThumb,
  FirstBlockRightThumbTitle,
  ThirdBlockLeftTitle,
  ThirdBlockLeftThumbs,
  ThirdBlockLeftThumbContainer,
  ThirdBlockLeftThumb,
  ThirdBlockLeftThumbTitle,
  ThirdBlockLeftThumbSubtitle,
  ThirdBlockLeftThumbSpecialties,
  ThirdBlockLeftThumbSpecialty,
  MenuIcon,
  FirstBlockCarrousel,
  FirstBlockCarrouselBullets,
  FirstBlockCarrouselBullet,
  TabContainer,
  TabContainerTitle,
  TabItem,
  TabItemImage,
  TabItemTexts,
  TabItemTitle,
  TabItemDescription,
  TabFavorites,
  TabFavoritesIcon,
  TabFavoritesContent,
  TabFavoritesContentItem,
  TabFavoritesContentItens,
  TabFavoritesContentTitle,
  TabFavoritesContentItemTitle,
  TabFavoritesContentItemImage,
  TabFavoritesContentItemTop,
  TabFavoritesContentItemText,
  Icon,
  TabItemIcon,
  TabItemDownloads,
  TabItemDownloadsIcon,
  TabItemDownloadsTexts,
  TabItemDownloadsTitle,
  TabItemDownloadsDescription,
} from "./styled";
import iconDownload from "../../../../assets/images/icon-download.png";
import iconCalculator from "../../../../assets/images/icon-calculator.png";
import iconFavorite from "../../../../assets/images/icon-favorite.png";
import abaFavoritosIcon from "../../../../assets/images/aba-favoritos-icon.png";
import { Trash } from "@styled-icons/bootstrap";
import { useRef } from "react";
import { isArray } from "lodash";
import { useFavorites } from "../../../../contexts/favorites";
import { FaFilePdf } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { BsFiletypeDoc } from "react-icons/bs";
import { SlDoc } from "react-icons/sl";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { SiBim } from "react-icons/si";

const LinkWithTooltip = ({ id, children, tooltip, placement }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement={placement}
      delayShow={300}
      delayHide={150}
    >
      {children}
    </OverlayTrigger>
  );
};

const SectionContent = ({
  setIsAnimating,
  setData,
  locationProps,
  location,
  setLocation,
  isInsideSection,
  setIsInsideSection,
  environment,
  refOrbit,
  local,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const scrollContainer = useScrollContainer();
  const scrollContainer2 = useScrollContainer();
  const scrollContainer3 = useScrollContainer();
  const [selectedImage, setSelectedImage] = useState(
    "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
  );
  const [updateImage, setUpdateImage] = useState(false);
  const [indexImage, setIndexImage] = useState(0);
  const [tabs, setTabs] = useState([]);
  const carrouselTimer = useRef(null);
  const [isOpenTab, setIsOpenTab] = useState(false);
  const { favoritesItens, setFavoritesItens } = useFavorites();

  const clone = require("rfdc")();

  const sanitizer = dompurify.sanitize;

  const closeInterna = () => {
    setIsOpenTab(false);
    setIsInsideSection(false);
    if (location !== null) {
      refOrbit.current.enableRotate = true;
      refOrbit.current.enableZoom = true;
      setData((values) => ({
        ...values,
        entryPos: {
          ...values.entryPos,
          [local]: {
            camPosX: locationProps[location].cameraOut.pos.x,
            camPosY: locationProps[location].cameraOut.pos.y,
            camPosZ: locationProps[location].cameraOut.pos.z,
            targetPosX: locationProps[location].cameraOut.target.x,
            targetPosY: locationProps[location].cameraOut.target.y,
            targetPosZ: locationProps[location].cameraOut.target.z,
            animationSpeed: locationProps[location].animationSpeed,
          },
        },
      }));
      setIsAnimating(true);
      setLocation(null);
      setSelectedImage(
        "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      );
    }
    navigate(`/in/${local}`);
  };

  const changeImage = (index) => {
    setIndexImage(index);
  };

  const toogleTabFavorites = () => {
    setIsOpenTab(!isOpenTab);
  };

  const deleteItem = (id) => {
    let favoriteItensTemp = clone(favoritesItens);
    let favoriteItensFiltered = favoriteItensTemp.filter(
      (item) => item.id !== id
    );
    setFavoritesItens(favoriteItensFiltered);
  };

  const saveItem = () => {
    let favoriteItensTemp = clone(favoritesItens);
    let prod = environment.products[location];
    let prodExist = favoriteItensTemp.some((item) => item.id === prod.id);
    if (!prodExist) {
      favoriteItensTemp.unshift({
        id: prod.id,
        text: prod?.high_description,
        title: prod?.name,
        image: prod?.image,
        url: window.location.href,
      });
      setFavoritesItens(favoriteItensTemp);
    }
  };

  const ItemFavorite = ({ id, title, image, text }) => {
    return (
      <TabFavoritesContentItem key={id} className={`${isMobile && "mobile"}`}>
        <TabFavoritesContentItemTop>
          <TabFavoritesContentItemImage src={image} />
          <TabFavoritesContentItemTitle>
            {title}
            <Icon>
              <LinkWithTooltip
                id="excluir-item"
                placement="right"
                tooltip={"Excluir item"}
              >
                <Trash
                  size={25}
                  onClick={() => deleteItem(id)}
                  style={{ pointerEvents: "all" }}
                />
              </LinkWithTooltip>
            </Icon>
          </TabFavoritesContentItemTitle>
        </TabFavoritesContentItemTop>
        <TabFavoritesContentItemText className={`${isMobile && "mobile"}`}>
          {text}
        </TabFavoritesContentItemText>
      </TabFavoritesContentItem>
    );
  };

  const donwloadFavoritesPDF = async (e, id = null) => {
    // Create a new jsPDF instance
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });

    // Set initial y position
    let yPos = 150;

    // Function to add a new page if needed
    function checkForNewPage(height) {
      if (yPos + height > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPos = 20;
      }
    }

    // Função para carregar imagens de forma assíncrona
    const loadImage = async (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // Adicione esta linha para lidar com CORS
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    };

    const imgHeader = await loadImage(
      "/assets/images/header-cidade-inteligente-new.png"
    );
    const imgProps = doc.getImageProperties(imgHeader);
    var width = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * width) / imgProps.width;

    doc.addImage(imgHeader, "PNG", 0, 0, width, pdfHeight);

    let favoritesItensTemp;

    // console.log(id);

    if (id !== null) {
      let prod = environment.products[location];
      favoritesItensTemp = [
        {
          id: prod?.id,
          text: prod?.high_description,
          title: prod?.name,
          image: prod?.image,
          url: window.location.href,
        },
      ];
    } else {
      favoritesItensTemp = clone(favoritesItens);
    }

    // Iterate through the array of objects
    for (const item of favoritesItensTemp) {
      try {
        // Check if we need a new page
        checkForNewPage(120);

        // Add image
        const img = await loadImage(item?.image);
        const aspectRatio = img.height / img.width;
        const imgHeight = 100 * aspectRatio;

        doc.addImage(img, "JPEG", 20, yPos, 100, imgHeight);

        // Set font for title
        doc.setFont("helvetica", "bold");
        doc.setFontSize(24);

        let finalTitle = item.title
          ? item.title.charAt(0).toUpperCase() + item.title.slice(1)
          : "";

        const splitTitle = doc.splitTextToSize(finalTitle, 280);
        doc.text(splitTitle, 140, yPos + 20);

        // console.log("splitTitle", splitTitle);

        // Set font for description
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);

        let finalText = item.text ? item.text : "";

        // Add description text with word wrap
        const splitText = doc.splitTextToSize(finalText, 280);
        doc.text(splitText, 140, yPos + 20 + 24 * splitTitle.length);

        // Gerar o QR Code como uma string de dados URL
        const qrCodeDataURL = await QRCode.toDataURL(item.url, {
          width: 100,
          margin: 2,
        });

        doc.addImage(
          qrCodeDataURL,
          "PNG",
          140,
          yPos + 20 + 24 * splitTitle.length + 12 * splitText.length,
          50,
          50
        );

        // Adicionar algum texto ao PDF
        doc.setFontSize(10);
        doc.text(
          item.url,
          140,
          yPos + 20 + 24 * splitTitle.length + 12 * splitText.length + 70
        );

        // Add some spacing between items
        yPos +=
          Math.max(
            imgHeight,
            splitText.length * 12 + splitTitle.length * 24 + 10 + 50,
            120
          ) + 60;

        // Add a line separator if it's not the last item
        if (item !== favoritesItensTemp[favoritesItensTemp.length - 1]) {
          doc.setDrawColor(200);
          doc.line(20, yPos - 10, doc.internal.pageSize.width - 20, yPos - 10);
        }
      } catch (error) {
        console.error(`Error processing item: ${item.title}`, error);
      }
    }

    // Save the PDF
    doc.save(
      `catalogo-favoritos-cidade-inteligente-${Date.now().toString()}.pdf`
    );
  };

  useEffect(() => {
    // console.log("environment", environment);
    if (location !== null) {
      // console.log("location", location);

      if (
        environment &&
        environment.products &&
        environment.products[location].gallery &&
        environment.products[location].gallery.length > 0
      ) {
        if (
          environment.products[location].gallery[indexImage] &&
          environment.products[location].gallery[indexImage]?.type === "image"
        ) {
          setSelectedImage(
            encodeURI(environment.products[location].gallery[indexImage].url)
          );
        }
      } else {
        setSelectedImage(
          "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
        );
      }
    }
  }, [environment, location, indexImage]);

  const nextValidIndexImage = (i) => {
    if (i <= environment?.products[location]?.gallery?.length - 1) {
      if (environment.products[location].gallery[i]?.type === "image") {
        return i;
      } else {
        return nextValidIndexImage(i + 1);
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    try {
      clearInterval(carrouselTimer.current);
    } catch (err) {}

    carrouselTimer.current = setInterval(() => {
      if (environment?.products[location]?.gallery.length > 0) {
        let newIndex = nextValidIndexImage(indexImage + 1);
        setIndexImage(newIndex);
        console.log("newIndex", newIndex);
      }
    }, 5000);
  }, [indexImage, environment]);

  useEffect(() => {
    // console.log("environment", environment);
    if (environment) {
      let product = environment.products[location];
      if (product) {
        let about =
          product?.environments?.filter(
            (env) => env?.name === environment?.name
          ) || [];

        let bim =
          product.bim !== null && product.bim !== undefined
            ? [
                {
                  url: product.bim,
                  title: product.bim_title,
                  type: "bim",
                },
              ]
            : [];

        let galleries = [];
        if (product.gallery) {
          let galleryFiltered = product.gallery.filter(
            (prod) => prod.type === "document"
          );
          if (galleryFiltered.length > 0) {
            galleries = galleryFiltered;
          }
        }

        let tabTemp = [
          {
            name: "Sobre o produto",
            nameTab: "Sobre",
            content:
              [about[0]?.pivot?.about, product.advantages, product.indication]
                .filter(Boolean)
                .join("<br />") || null,
          },
          {
            name: "Informações Técnicas",
            nameTab: "Info. Técnicas",
            content: product.description,
          },
          {
            name: "Sistema completo",
            nameTab: "Sis. completo",
            content: product.systems,
          },
          {
            name: "Produtos similares",
            nameTab: "Prod. similares",
            content: product.indications,
          },
          {
            name: "Sustentabilidade",
            nameTab: "Sustentabilidade",
            content: product.sustainability,
          },
          {
            name: "Downloads",
            nameTab: "Downloads",
            content: [...galleries, ...bim],
          },
        ];
        setTabs(tabTemp);
      }
    }
  }, [environment, location]);

  useEffect(() => {
    // console.log("tabs", tabs);
  }, [tabs]);

  useEffect(() => {
    // console.log(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (environment !== null && location !== null) {
      setIndexImage(nextValidIndexImage(0));
    }
  }, [environment, location]);

  return (
    <Offcanvas
      className="offCanvasInternaSection"
      show={isInsideSection}
      placement={isMobile ? "end" : "end"}
      backdrop={true}
      onHide={() => closeInterna()}
      // w-100
      style={{
        height: "100vh",
        pointerEvents: "all",
      }}
    >
      <Offcanvas.Body>
        <TabFavorites
          className={`${isOpenTab && "on"} ${isMobile && "mobile"}`}
        >
          <LinkWithTooltip
            id="itens-salvos"
            placement="left"
            tooltip={isOpenTab ? "Fechar itens salvos" : "Abrir itens salvos"}
          >
            <TabFavoritesIcon
              onClick={() => toogleTabFavorites()}
              className={`${isMobile && "mobile"}`}
            />
          </LinkWithTooltip>
          <TabFavoritesContent className={`${isMobile && "mobile"}`}>
            <TabFavoritesContentTitle>
              Itens Salvos
              <LinkWithTooltip
                id="itens-salvos"
                placement="bottom"
                tooltip={"Download dos itens salvos"}
              >
                <MenuIcon onClick={donwloadFavoritesPDF}>
                  <img
                    src={iconDownload}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                </MenuIcon>
              </LinkWithTooltip>
            </TabFavoritesContentTitle>
            <TabFavoritesContentItens>
              {favoritesItens?.map((item) => (
                <ItemFavorite
                  id={item.id}
                  title={item.title}
                  image={item?.image}
                  text={item.text}
                />
              ))}
            </TabFavoritesContentItens>
          </TabFavoritesContent>
        </TabFavorites>

        <AllBlocks className={`${isMobile && "mobile"}`}>
          <CloseInterna onClick={() => closeInterna()} classe={"interna"} />
          <LeftBlock className={`${isOpenTab && "on"} ${isMobile && "mobile"}`}>
            <FirstBlock>
              <FirstBlockTop>
                <FirstBlockCarrousel className={`${isMobile && "mobile"}`}>
                  <FirstBlockImage>
                    {selectedImage && (
                      <img
                        src={selectedImage}
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                  </FirstBlockImage>
                  <FirstBlockCarrouselBullets ref={scrollContainer.ref}>
                    {environment !== null &&
                      location !== null &&
                      environment.products[location].gallery !== null &&
                      environment.products[location].gallery !== undefined &&
                      environment.products[location].gallery.map(
                        (item, index) =>
                          item?.type === "image" && (
                            <FirstBlockCarrouselBullet
                              key={index}
                              className={index === indexImage ? "on" : ""}
                              src={encodeURI(item.url)}
                              onClick={() => changeImage(index)}
                            />
                          )
                      )}
                  </FirstBlockCarrouselBullets>
                </FirstBlockCarrousel>
                <FirstBlockTopRight>
                  <FirstBlockMenu>
                    <LinkWithTooltip
                      id="download-product"
                      placement="top"
                      tooltip={"Download deste item"}
                    >
                      <MenuIcon
                        onClick={(e) => {
                          donwloadFavoritesPDF(
                            e,
                            environment.products[location].id
                          );
                        }}
                      >
                        <img
                          src={iconDownload}
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      </MenuIcon>
                    </LinkWithTooltip>
                    {environment !== null &&
                      location !== null &&
                      environment.products[location].calculator && (
                        <LinkWithTooltip
                          id="calculator"
                          placement="top"
                          tooltip={"Calculadora"}
                        >
                          <MenuIcon
                            onClick={() =>
                              window.open(
                                environment.products[location].calculator,
                                "_blank"
                              )
                            }
                          >
                            <img
                              src={iconCalculator}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover" }}
                            />
                          </MenuIcon>
                        </LinkWithTooltip>
                      )}
                    <LinkWithTooltip
                      id="save-product"
                      placement="top"
                      tooltip={"Salvar item"}
                    >
                      <MenuIcon onClick={saveItem}>
                        <img
                          src={iconFavorite}
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      </MenuIcon>
                    </LinkWithTooltip>
                  </FirstBlockMenu>
                  <FirstBlockTopRightText>
                    <FirstBlockTitle>
                      {environment !== null &&
                        location !== null &&
                        environment.products[location]?.name}
                    </FirstBlockTitle>
                    <FirstBlockText>
                      {environment !== null &&
                      location !== null &&
                      environment.products[location]?.high_description
                        ? environment.products[location]?.high_description
                        : "Sem descrição"}
                    </FirstBlockText>
                  </FirstBlockTopRightText>
                </FirstBlockTopRight>
              </FirstBlockTop>
            </FirstBlock>
            <div
              style={{
                position: "relative",
                backgroundColor: "#ffffff",
                display: "block",
                width: "100%",
                minHeight: "100%",
              }}
            >
              <Tabs
                defaultActiveKey={
                  tabs.filter(
                    (tab) =>
                      tab.content !== null ||
                      (isArray(tab.content) && tab.content.length > 0)
                  )[0]?.nameTab
                }
                id="justify-tab-example"
                className="mb-3"
              >
                {tabs.map(
                  (tab) =>
                    ((isArray(tab.content) && tab.content.length > 0) ||
                      (!isArray(tab.content) && tab.content !== null)) && (
                      // true
                      <Tab
                        key={tab?.name}
                        eventKey={tab.nameTab}
                        title={tab.nameTab}
                        style={{ pointerEvents: "all" }}
                      >
                        {typeof tab.content === "object" ? (
                          isArray(tab.content) ? (
                            <TabContainer>
                              <TabContainerTitle>{tab?.name}</TabContainerTitle>
                              {tab.content.map((item) =>
                                item?.type && item.type === "document" ? (
                                  <TabItemDownloads key={uuidv4()}>
                                    <a
                                      target="_blank"
                                      href={item.url}
                                      download={item.url.split("/").pop()}
                                    >
                                      <TabItemDownloadsIcon>
                                        <IconContext.Provider
                                          value={{
                                            size: "50",
                                            color: "rgb(40, 56, 145)",
                                            className: "contactIcon",
                                          }}
                                        >
                                          {item.url.split(".").pop() ===
                                          "pdf" ? (
                                            <FaFilePdf />
                                          ) : item.url.split(".").pop() ===
                                              "doc" ||
                                            item.url.split(".").pop() ===
                                              "docx" ? (
                                            <BsFiletypeDoc />
                                          ) : (
                                            <SlDoc />
                                          )}
                                        </IconContext.Provider>
                                      </TabItemDownloadsIcon>
                                      <TabItemDownloadsTexts>
                                        <TabItemDownloadsTitle>
                                          {item.title}
                                        </TabItemDownloadsTitle>
                                        {/* <TabItemDownloadsDescription>
                                          {item.url.split("/").pop()}
                                        </TabItemDownloadsDescription> */}
                                      </TabItemDownloadsTexts>
                                    </a>
                                  </TabItemDownloads>
                                ) : item?.type && item.type === "bim" ? (
                                  item.url ? (
                                    <TabItemDownloads key={uuidv4()}>
                                      <a target="_blank" href={item.url}>
                                        <TabItemDownloadsIcon>
                                          <IconContext.Provider
                                            value={{
                                              size: "50",
                                              color: "rgb(40, 56, 145)",
                                              className: "contactIcon",
                                            }}
                                          >
                                            <SiBim />
                                          </IconContext.Provider>
                                        </TabItemDownloadsIcon>
                                        <TabItemDownloadsTexts>
                                          <TabItemDownloadsTitle>
                                            {item.title}
                                          </TabItemDownloadsTitle>
                                        </TabItemDownloadsTexts>
                                      </a>
                                    </TabItemDownloads>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <TabItem key={uuidv4()}>
                                    <TabItemImage
                                      src={item?.image}
                                      width="50"
                                    />
                                    <TabItemTexts>
                                      <TabItemTitle>{item?.name}</TabItemTitle>
                                      <TabItemDescription>
                                        {tab?.name === "Produtos similares"
                                          ? item.pivot?.about &&
                                            item.pivot?.about !== ""
                                            ? item.pivot.about
                                            : "Sem descriçõo"
                                          : tab?.name === "Sistema completo"
                                          ? item.pivot?.description &&
                                            item.pivot?.description !== ""
                                            ? item?.pivot?.description
                                            : "Sem descrição"
                                          : item?.high_description &&
                                            item?.high_description !== ""
                                          ? item?.high_description
                                          : "Sem descrição"}
                                      </TabItemDescription>
                                    </TabItemTexts>
                                  </TabItem>
                                )
                              )}
                            </TabContainer>
                          ) : (
                            <TabContainer>
                              <TabContainerTitle>{tab?.name}</TabContainerTitle>
                            </TabContainer>
                          )
                        ) : (
                          <TabContainer>
                            <TabContainerTitle>{tab?.name}</TabContainerTitle>
                            <TabItemTexts
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(tab.content, {
                                  ADD_TAGS: ["iframe"],
                                  ADD_ATTR: [
                                    "allow",
                                    "allowfullscreen",
                                    "frameborder",
                                    "scrolling",
                                    "width",
                                    "height",
                                    "src",
                                  ],
                                }),
                              }}
                            ></TabItemTexts>
                          </TabContainer>
                        )}
                      </Tab>
                    )
                )}
              </Tabs>
            </div>
          </LeftBlock>
        </AllBlocks>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SectionContent;
