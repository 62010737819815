import React, { useEffect } from "react";
import { Container, TitleClose } from "./style";
import CloseInterna from "./CloseInterna";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Container
      onClick={() => {
        // window.location.href = process.env.PUBLIC_URL + "/";
        // navigate("/");
        window.location.href = "/";
      }}
    >
      {/* <TitleClose>Cidade inteligente</TitleClose> */}
      <CloseInterna />
    </Container>
  );
};

export default Header;
