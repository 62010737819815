import styled from "styled-components";
import { theme } from "../../../../theme";

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 0px 0px;
`;

export const Title = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  ${theme.fonts.h4};
  color: ${theme.colors.blue};
  .count {
    color: ${theme.colors.greylight};
  }
`;

export const Description = styled.div`
  ${theme.fonts.caption}
  color: ${theme.colors.greyDark};
  margin-top: 5px;
  width: 100%;
  text-align: center;
`;

export const EnterText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.fonts.caption}
  color: ${theme.colors.white};
  width: 70%;
  margin-top: 5px;
  padding: 10px;
  text-align: center;
  background-color: ${theme.colors.blue};
  border-radius: 40px;
  text-transform: uppercase;
  white-space: pre-line;
  opacity: 0.8;
`;
