import { useRef, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import * as THREE from "three";
import { invalidate, useLoader, useThree } from "@react-three/fiber";
import {
  Circle,
  Backdrop,
  Billboard,
  Image,
  Text,
  Html,
} from "@react-three/drei";
import { LayerMaterial, Depth } from "lamina";

import plusTexture from "../../../../assets/images/plus.png";
import trezentosesessenta from "../../../../assets/images/360.png";
import plusDesaturatedTexture from "../../../../assets/images/plus-desaturated.png";
import { TextContainer, Title, Description, EnterText } from "./styled.js";
import { useNavigate } from "react-router-dom";

const BillboardMenu = ({
  follow,
  lockX,
  lockY,
  lockZ,
  setPlaceHighlighted,
  locations,
  setLocations,
  location,
  setLocation,
  tabMenu,
  setTabMenu,
  setIsAnimating,
  setData,
  locationProps,
  refOrbit,
  locationData,
}) => {
  const { camera } = useThree();
  const plusMap = useLoader(THREE.TextureLoader, plusTexture);
  const plusDesaturatedMap = useLoader(
    THREE.TextureLoader,
    plusDesaturatedTexture
  );
  const ref = useRef();
  const clone = require("rfdc")();
  const [hovered, setHovered] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [localLocationProps, setLocalLocationProps] = useState(locationProps);
  const navigate = useNavigate();

  let size = 1;
  let emissive = "purple";
  let glow = "#90a5ff";
  let scale = size * 8;
  let near = -10;
  let far = 1.2;
  let color = glow || emissive || "white";

  const clicked = (e, index) => {
    // console.log(locationProps);
    e.stopPropagation();
    setLocations([]);
    let locationProp = localLocationProps.find((prop) => prop.id === index);
    setPlaceHighlighted(locationProp.materialId);
    setLocation(index);
    let newTabMenu = clone(tabMenu);
    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        if (i === 0 && item.id === index) {
          item.selected = true;
          return item;
        } else {
          item.selected = false;
          return item;
        }
      });
    });
    setTabMenu(newTabMenu);
    setData((values) => ({
      ...values,
      camPosX: isMobile
        ? locationProp.cameraIn.posMobile.x
        : locationProp.cameraIn.pos.x,
      camPosY: isMobile
        ? locationProp.cameraIn.posMobile.y
        : locationProp.cameraIn.pos.y,
      camPosZ: isMobile
        ? locationProp.cameraIn.posMobile.z
        : locationProp.cameraIn.pos.z,
      targetPosX: isMobile
        ? locationProp.cameraIn.targetMobile.x
        : locationProp.cameraIn.target.x,
      targetPosY: isMobile
        ? locationProp.cameraIn.targetMobile.y
        : locationProp.cameraIn.target.y,
      targetPosZ: isMobile
        ? locationProp.cameraIn.targetMobile.z
        : locationProp.cameraIn.target.z,
      animationSpeed: locationProp.animationSpeed,
    }));
    setIsAnimating(true);
    refOrbit.current.enableRotate = false;
    refOrbit.current.enableZoom = false;
  };

  const onOver = (e, index) => {
    // console.log(localLocationProps);
    e.stopPropagation();
    setHovered(true);
    if (location === null) {
      let locationProp = localLocationProps.find((prop) => prop.id === index);
      setPlaceHighlighted(locationProp.materialId);
      setIsAnimating(false);
    }
  };

  const onOut = (e, index) => {
    // console.log(localLocationProps);
    e.stopPropagation();
    setLocations([]);
    setHovered(false);
    if (location === null) {
      let locationProp = localLocationProps.find((prop) => prop.id === index);
      setPlaceHighlighted(null);
      setIsAnimating(false);
    }
  };

  const getIn = (e, index) => {
    e.stopPropagation();
    let locationProp = localLocationProps.find((prop) => prop.id === index);
    // if (locationProp.products.length > 0) {
    //   let routeName = locationProp.name.trim().toLowerCase().replace(/\s/g, "");
    //   routeName = routeName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    //   window.location.href = process.env.PUBLIC_URL + "/in/" + routeName;
    // }
    let routeName = locationProp.name.trim().toLowerCase().replace(/\s/g, "-");
    routeName = routeName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    window.location.href = process.env.PUBLIC_URL + "/in/" + routeName;
    // navigate("/in/" + routeName);
  };

  useEffect(() => {
    setLocalLocationProps(locationProps);
  }, [locationProps]);

  useEffect(() => {
    // console.log(localLocationProps);
  }, [localLocationProps]);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <>
      {localLocationProps.map((locationProp, index) => {
        return (
          <Billboard
            key={index}
            follow={follow}
            lockX={lockX}
            lockY={lockY}
            lockZ={lockZ}
            position={locationProp.pinPos}
          >
            <Circle
              args={[10, 20]}
              onPointerEnter={(e) => {
                onOver(e, locationProp.id);
              }}
              onPointerLeave={(e) => {
                onOut(e, locationProp.id);
              }}
              onClick={(e) => {
                clicked(e, locationProp.id);
              }}
              renderOrder={1000}
            >
              <meshBasicMaterial
                map={plusMap}
                transparent
                depthTest={true}
                depthWrite={true}
              />
            </Circle>

            {(location === locationProp.id ||
              locations?.includes(locationProp.id)) && (
              <Html
                ref={ref}
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "10px",
                  gap: "5px",
                  userSelect: "none",
                  width: "220px",
                  height: "auto",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  zIndex: "0",
                  cursor: "pointer",
                }}
                scale={7}
                position={[0, 40, 0]}
                transform
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyComtemt: "center",
                    userSelect: "none",
                    width: "200px",
                    height: "150px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    zIndex: "0",
                  }}
                  onPointerDown={(e) => getIn(e, locationProp.id)}
                >
                  {/* <img
                    src={trezentosesessenta}
                    width="100px"
                    height="45px"
                    style={{
                      position: "absolute",
                      objectFit: "contain",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  /> */}
                  <EnterText>{`Clique
                  para entrar`}</EnterText>
                  <img
                    src={process.env.PUBLIC_URL + locationProp.thumb}
                    width={"100%"}
                    height={"100%"}
                    style={{ pointerEvents: "none", objectFit: "cover" }}
                  />
                </div>
                <TextContainer onPointerDown={(e) => getIn(e, locationProp.id)}>
                  <Title>{locationProp.name}</Title>
                  {/* <Description>CLIQUE PARA ACESSAR</Description> */}
                </TextContainer>
              </Html>
            )}
          </Billboard>
        );
      })}
    </>
  );
};

export default BillboardMenu;
