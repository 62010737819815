import React, { useEffect, useContext, useState, createContext } from "react";

const FavoritesContext = createContext();

const FavoritesProvider = ({ children }) => {
  const [favoritesItens, setFavoritesItens] = useState([]);

  useEffect(() => {}, [favoritesItens]);

  return (
    <FavoritesContext.Provider value={{ favoritesItens, setFavoritesItens }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (!context)
    throw new Error("useFavorites must be used within a FavoritesProvider");

  const { favoritesItens, setFavoritesItens } = context;

  return { favoritesItens, setFavoritesItens };
};

export default FavoritesProvider;
