import styled from "styled-components";

import logoBrasilit from "../../../../assets/images/logos/brasilit.png";
import logoQuartzolit from "../../../../assets/images/logos/quartzolit.png";
import logoPlaco from "../../../../assets/images/logos/placo.png";
import logoIsover from "../../../../assets/images/logos/isover.png";
import logoEcophon from "../../../../assets/images/logos/ecophon.png";
import logoSonex from "../../../../assets/images/logos/sonex.png";
import logoOwa from "../../../../assets/images/logos/owa.png";

import logoSaintGobain from "../../../../assets/images/logo-saint-gobain.png";
import logoParceiroDaConstrucao from "../../../../assets/images/logo-parceiro-da-construcao.png";
export const MenuHolder = styled.div`
  cursor: pointer;
  pointer-events: all;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  top: 30px;
  right: 40px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  &.mobile {
    width: 40px;
    height: 40px;
    top: 30px;
    right: 30px;
    left: unset;
    bottom: unset;
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Menu = styled.input.attrs({ type: "checkbox" })`
  pointer-events: none;
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  // top: calc((70px - 20px) / 2);
  z-index: 100;
  opacity: 0;
  z-index: 10;

  &:checked {
    background-color: #4caf50;
  }

  &:checked + label:before {
    background-color: #4caf50;
  }

  &:checked + label div:first-child {
    top: -4px;
    right: -4px;
    transform: rotateZ(45deg);
  }

  &:checked + label div:last-child {
    top: 5px;
    right: -1px;
    transform: rotateZ(45deg);
  }

  &:checked + label div:nth-child(2) {
    width: 37px;
    top: 0px;
    right: 1px;
    transform: rotateZ(-45deg);
  }
`;

export const MenuLines = styled.label`
  pointer-events: none;
  position: absolute;
  display: block;
  width: 30px;
  height: 20px;
  z-index: 1;
  // top: calc((70px - 20px) / 2);
  transform: scale(0.7);
  &.on {
    transform: scale(0.7) translateX(-3px);
  }

  &.mobile {
    transform: scale(0.55);
    &.on {
      transform: scale(0.55) translateX(-3px);
    }
  }

  &:before {
    content: "";
    transition: 1.2s cubic-bezier(0, 0.96, 1, 0.02) background-color;
  }
`;

export const MenuLine = styled.div`
  position: relative;
  top: 0;
  height: 2px;
  background-color: #ffffff;
  margin-bottom: 7px;
  transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
    0.3s ease right;
  border-radius: 2px;

  &:first-child {
    transform-origin: 0px;
  }

  &:last-child {
    margin-bottom: 0;
    transform-origin: 30px;
  }

  &:nth-child(2) {
    right: 0px;
    width: 30px;
  }
`;

export const OffShort = styled.div`
  width: 100%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const OffFullBanner = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.display}
  color: ${({ theme }) => theme.colors.sky};
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 50px 40px;
  text-align: center;

  img {
    width: 25%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 42px;
    line-height: 53.59px;
    text-align: center;

    img {
      width: 80%;
    }
  }
`;

export const Assinatura = styled.div`
  ${({ theme }) => theme.fonts.assinatura} !important;
  color: ${({ theme }) => theme.colors.blue};
  text-transform: none;
  margin-top: 20px;
`;

export const OffShortTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0rem 0rem 0rem;
  ${({ theme }) => theme.fonts.bodytitle}
  color: ${({ theme }) => theme.colors.black};

  @media only screen and (max-width: 600px) {
    padding: 0rem 0rem 0rem;
  }
`;

export const OffShortTwoColumns = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0rem 3rem;

  @media only screen and (max-width: 600px) {
    flex-flow: column wrap;
  }
`;

export const OffShortOneColumn = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0rem 3rem;

  @media only screen and (max-width: 600px) {
    flex-flow: column wrap;
  }
`;

export const OffShortContent0 = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.bodysmall}
  color: ${({ theme }) => theme.colors.black};
  padding: 3rem 0rem;
  column-count: 2;
  column-gap: 80px;
  column-rule-style: none;
  line-height: 16px;
  white-space: pre-line;

  @media only screen and (max-width: 600px) {
    width: 100%;
    column-count: 1;
    column-gap: 0px;
  }
`;

export const OffShortContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 50%;
  ${({ theme }) => theme.fonts.bodysmall}
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid #4e5ba2;
  padding: 1rem 0rem;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const OffShortContent2 = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  ${({ theme }) => theme.fonts.bodysmall}
  color: ${({ theme }) => theme.colors.black};
  padding: 3rem 0rem 3rem 3rem;

  &.noPadding {
    padding: 1rem 0rem 3rem 3rem;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 3rem 0rem !important;
  }
`;

export const Link = styled.a`
user-select:none;
cursor:pointer;
text-align:left;
font: ${({ theme }) => theme.fonts.bodysmallLink}
color: ${({ theme }) => theme.colors.black};
`;

export const OffShortLogos = styled.div`
  width: 80%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  border-radius: 8px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-flow: row wrap;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
export const OffShortLogo = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  width: 20%;
  height: 60px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    width: 20%;
    height: 56px;
  }

  .logo {
    width: 85%;
    height: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    border-radius: 8px;
    transform: skew(11deg, 0deg);

    @media only screen and (max-width: 600px) {
      width: 85%;
      height: 100%;
    }

    &.quartzolit {
      background-image: url(${logoQuartzolit});
    }
    &.brasilit {
      background-image: url(${logoBrasilit});
    }
    &.placo {
      background-image: url(${logoPlaco});
    }
    &.isover {
      background-image: url(${logoIsover});
    }
    &.ecophon {
      background-image: url(${logoEcophon});
    }
    &.sonex {
      background-image: url(${logoSonex});
    }
    &.owa {
      background-image: url(${logoOwa});
    }
  }
`;

export const OffFull = styled.div`
  width: 100%;
  padding: 0px 40px 20px;
  display: flex;
  flex-flow: column wrap;
  background-color: ${({ theme }) => theme.colors.yellow};
`;

export const OffFullTitle = styled.div`
  ${({ theme }) => theme.fonts.bodytitle}
  color: ${({ theme }) => theme.colors.black};
  margin-top: 50px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkblue};
`;

export const OffFullThree = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 50px 0;
  @media only screen and (max-width: 600px) {
    flex-flow: column wrap;
  }
`;
export const OffFullThreeItem = styled.div`
  width: 30.33%;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-bottom: 40px;
  }
`;
export const OffFullThreeItemTitle = styled.div`
  ${({ theme }) => theme.fonts.h3};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;
export const OffFullThreeItemContent = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;
export const OffFullThreeItemLink = styled.a`
  text-decoration: none;
  user-select: none;
  ${({ theme }) => theme.fonts.bodytitle};
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  padding: 5px 10px;

  &:link {
    color: ${({ theme }) => theme.colors.black};
  }

  &:visited {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  &:active {
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`;

export const OffFullTwo = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 50px 0;
`;
export const OffFullTwoItem = styled.div`
  width: 47%;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-bottom: 40px;
  }
`;
export const OffFullTwoItemLogo = styled.div`
  width: 150px;
  height: 32px;
  background-repeat: no-repeat;

  &.saintgobain {
    background-image: url(${logoSaintGobain});
  }

  &.parceirodaconstrucao {
    background-image: url(${logoParceiroDaConstrucao});
  }
`;
export const OffFullTwoItemImagem = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  margin: 30px 0;
`;
export const OffFullTwoItemLink = styled.a`
  width: 100%;
  user-select: none;
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.offwhite};
  margin-bottom: 20px;
  text-decoration: none;
  &:after {
    user-select: none;
    content: " ↗";
    color: ${({ theme }) => theme.colors.sky};
  }
  &:link {
    color: ${({ theme }) => theme.colors.offwhite};
  }

  &:visited {
    color: ${({ theme }) => theme.colors.sky};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.offwhite};
  }

  &:active {
    color: ${({ theme }) => theme.colors.offwhite};
  }
`;
export const OffFullTwoItemTitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.greylight};
  margin-bottom: 10px;
`;
export const OffFullTwoItemWhatsapp = styled.a`
display: flex;
width: 100%;
white-space: pre;
${({ theme }) => theme.fonts.bodysmall}}
color: ${({ theme }) => theme.colors.offwhite};
margin-bottom: 10px;
text-decoration: none;

&:before {
  user-select: none;
  content: "whatsapp ";
  color: ${({ theme }) => theme.colors.greylight};
}

&:link {
  color: ${({ theme }) => theme.colors.offwhite};
}

&:visited {
  color: ${({ theme }) => theme.colors.sky};
}

&:hover {
  color: ${({ theme }) => theme.colors.offwhite};
}

&:active {
  color: ${({ theme }) => theme.colors.offwhite};
}
`;

export const OffFullTwoItemEmail = styled.a`
display: flex;
width: 100%;
white-space: pre;
${({ theme }) => theme.fonts.bodysmall}}
color: ${({ theme }) => theme.colors.offwhite};
margin-bottom: 10px;
text-decoration: none;

&:before {
  user-select: none;
  content: "Email ";
  color: ${({ theme }) => theme.colors.greylight};
}

&:link {
  color: ${({ theme }) => theme.colors.offwhite};
}

&:visited {
  color: ${({ theme }) => theme.colors.sky};
}

&:hover {
  color: ${({ theme }) => theme.colors.offwhite};
}

&:active {
  color: ${({ theme }) => theme.colors.offwhite};
}
`;
