/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 city-test.glb -k -K -s
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

import model from "./d.glb";

export function C(props) {
  const { nodes, materials } = useGLTF(model);

  // const mat = new THREE.MeshPhysicalMaterial({
  //   metalness: 0,
  //   roughness: 0.5,
  //   clearcoat: 1,
  //   transparent: true,
  //   transmission: 0.3,
  //   opacity: 1,
  //   reflectivity: 0.3,
  // });
  const mat = new THREE.MeshPhongMaterial({
    metalness: 0,
    roughness: 0.5,
  });
  mat.color = new THREE.Color("#ffffff");
  mat.envMapIntensity = 0.3;

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Sketchfab_model"
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        >
          <group name="98b241ba40ed462f854a6a49e3dca488objcleanermaterialmergergle">
            <mesh
              name="Object_3"
              castShadow
              receiveShadow
              geometry={nodes.Object_3.geometry}
              material={mat}
            />
            <mesh
              name="Object_4"
              castShadow
              receiveShadow
              geometry={nodes.Object_4.geometry}
              material={mat}
            />
            <mesh
              name="Object_5"
              castShadow
              receiveShadow
              geometry={nodes.Object_5.geometry}
              material={mat}
            />
            <mesh
              name="Object_6"
              castShadow
              receiveShadow
              geometry={nodes.Object_6.geometry}
              material={mat}
            />
            <mesh
              name="Object_7"
              castShadow
              receiveShadow
              geometry={nodes.Object_7.geometry}
              material={mat}
            />
            <mesh
              name="Object_8"
              castShadow
              receiveShadow
              geometry={nodes.Object_8.geometry}
              material={mat}
            />
            <mesh
              name="Object_9"
              castShadow
              receiveShadow
              geometry={nodes.Object_9.geometry}
              material={mat}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(model);
