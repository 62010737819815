import React, { Suspense, useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  SplitButton,
  ButtonGroup,
} from "react-bootstrap";
import * as THREE from "three";
import { useLoader, Canvas, invalidate, useThree } from "@react-three/fiber";
import {
  Stats,
  AdaptiveDpr,
  AdaptiveEvents,
  PerspectiveCamera,
  Environment,
  BakeShadows,
} from "@react-three/drei";
import { isMobile, isBrowser } from "react-device-detect";
import {
  getLocal,
  getLocalProducts,
  getCategories,
  getSolutionsProducts,
} from "../../services/api";
import { usePreload } from "../../contexts/preload";
import Header from "./components/Header";
import SectionContent from "./components/SectionContent";
import DirectionalLight from "./components/DirectionalLight";
import BillboardMenu from "./components/BillboardMenu";
import CameraControls from "./components/CameraControls";
import AnimateCamera from "./components/AnimateCamera";
import PreloaderCanvas from "../../shared/Components/PreloaderCanvas";
import { configData, configDataMobile, locationsOut } from "./setup";
import productsEdificioResidencial from "./productsEdificioResidencial";
import productsHospital from "./productsHospital";
import productsShopping from "./productsShopping";
import productsHotel from "./productsHotel";
import productsResidenciaUnifamiliar from "./productsResidenciaUnifamiliar";
import productsEscola from "./productsEscola";
import productsParqueEolico from "./productsParqueEolico";
import productsEscritorio from "./productsEscritorio";
import productsGalpao from "./productsGalpao";
import productsInfraestrutura from "./productsInfraestrutura";
import productsIndustria from "./productsIndustria";
import productsPonte from "./productsPonte";
import productsAviario from "./productsAviario";
import "./style.scss";
import envi from "../../assets/images/SkyHDR_1k.hdr";
import enviCasa from "../../assets/images/blouberg_sunrise_2_1k.hdr";
import enviCasa2 from "../../assets/images/HDR_029_Sky_Cloudy_Env.hdr";
import enviComercial from "../../assets/images/canary_wharf_1k.hdr";
import enviIndustria from "../../assets/images/abandoned_parking_1k.hdr";
import {
  InternaLocationPin,
  EnvironmentsMenu,
  EnvironmentsMenuTitle,
  CliqueArraste,
} from "./styled";
import pin from "../../assets/images/logo-cidade-inteligente-new-simbolo.png";
import CamTweak from "./helpers/CamTweak";
import { ResizeImage } from "styled-icons/fluentui-system-filled";
import { Effects } from "../../assets/Modelos/ambientes-compressed/Effects";
import abandonedPark from "../../assets/images/abandoned_parking_1k.hdr";

const Residencia = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/ResidenciaMobile")
    : import("../../assets/Modelos/ambientes-compressed/Residencia")
);
const Hotel = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/HotelMobile")
    : import("../../assets/Modelos/ambientes-compressed/Hotel")
);
const Hospital = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/HospitalMobile")
    : import("../../assets/Modelos/ambientes-compressed/Hospital")
);
const Galpao = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/GalpaoMobile")
    : import("../../assets/Modelos/ambientes-compressed/Galpao")
);
const Escola = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/EscolaMobile")
    : import("../../assets/Modelos/ambientes-compressed/Escola")
);
const Comercial = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/ComercialMobile")
    : import("../../assets/Modelos/ambientes-compressed/Comercial")
);
const Casa = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/CasaMobile")
    : import("../../assets/Modelos/ambientes-compressed/Casa")
);
const Tunel = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/TunelMobile")
    : import("../../assets/Modelos/ambientes-compressed/Tunel")
);
const Industria = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/IndustriaMobile")
    : import("../../assets/Modelos/ambientes-compressed/Industria")
);
const Shopping = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/ShoppingMobile")
    : import("../../assets/Modelos/ambientes-compressed/Shopping")
);
const ParqueEolico = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/ambientes-compressed/ParqueEolicoMobile")
    : import("../../assets/Modelos/ambientes-compressed/ParqueEolico")
);

const Cidade = React.lazy(() =>
  isMobile
    ? import("../../assets/Modelos/cidade-in")
    : import("../../assets/Modelos/cidade-in")
);

const WebGlListener = (props) => {
  const { gl } = useThree();
  useEffect(() => {
    const canvas = gl.domElement;
    canvas.addEventListener(
      "webglcontextlost",
      function (event) {
        event.preventDefault();
        setTimeout(function () {
          console.log("tentativa de restaurar Contexto Webgl");
          gl.forceContextRestore();
        }, 1);
      },
      false
    );
  }, []);
  return <>{props.children}</>;
};

const Interna = ({
  burgerMenuOn,
  setBurgerMenuOn,
  secondaryMenuOn,
  setSecondaryMenuOn,
  isAnimating,
  setIsAnimating,
}) => {
  const { local, product } = useParams();
  const navigate = useNavigate();
  const refOrbit = useRef(null);
  const virtualCameraRef = useRef(null);
  const [location, setLocation] = useState(null);
  const [environments, setEnvironments] = useState([]);
  const [locationProps, setLocationProps] = useState(locationsOut);
  const [environment, setEnvironment] = useState(null);
  const [data, setData] = useState(isMobile ? configDataMobile : configData);
  const [isInsideSection, setIsInsideSection] = useState(false);
  const [environmentArea, setEnvironmentArea] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [showCliqueArraste, setShowCliqueArraste] = useState(false);
  const { preload, setPreload } = usePreload();

  const clone = require("rfdc")();

  const goEnvironments = (routeName) => {
    // window.location.href = process.env.PUBLIC_URL + "/in/" + routeName;
    window.location.href = "/in/" + routeName;
  };

  const enterProduct = (index) => {
    if (environment !== null && environment.products) {
      if (
        environment.products[index].name === "Entrar" ||
        environment.products[index].name === "Subir"
      ) {
        setEnvironmentArea(0);
      } else if (
        environment.products[index].name === "Sair" ||
        environment.products[index].name === "Descer"
      ) {
        setEnvironmentArea(0);
      }
      if (
        environment.products[index].id !== 0 &&
        environment.products[index].id !== -1
      ) {
        setIsInsideSection(true);
        setLocation(index);
        // console.log("props", index);
      } else {
        setLocation(null);
      }
      setData((values) => ({
        ...values,
        entryPos: {
          ...values,
          [local]: {
            camPosX: isMobile
              ? environment.products[index].cameraIn.posMobile.x
              : environment.products[index].cameraIn.pos.x,
            camPosY: isMobile
              ? environment.products[index].cameraIn.posMobile.y
              : environment.products[index].cameraIn.pos.y,
            camPosZ: isMobile
              ? environment.products[index].cameraIn.posMobile.z
              : environment.products[index].cameraIn.pos.z,
            targetPosX: isMobile
              ? environment.products[index].cameraIn.targetMobile.x
              : environment.products[index].cameraIn.target.x,
            targetPosY: isMobile
              ? environment.products[index].cameraIn.targetMobile.y
              : environment.products[index].cameraIn.target.y,
            targetPosZ: isMobile
              ? environment.products[index].cameraIn.targetMobile.z
              : environment.products[index].cameraIn.target.z,
            pinPos: environment.products[index].pinPos,
          },
        },
        animationSpeed: environment.products[index].animationSpeed,
      }));
      if (environment.products[index].id > 0) {
        // refOrbit.current.enableRotate = false;
        // refOrbit.current.enableZoom = false;
      }
    }
    setTimeout(() => {
      setIsAnimating(true);
    }, 600);
  };

  useEffect(() => {
    setPreload(true);
    setData((values) => ({
      ...values,
      animationSpeed: 0.025,
      camPosX: data.camPosX,
      camPosY: data.camPosY,
      camPosZ: data.camPosZ,
      targetPosX: data.targetPosX,
      targetPosY: data.targetPosY,
      targetPosZ: data.targetPosZ,
      maxPolarAngle: Math.PI / 3,
    }));

    getLocalProducts()
      .then((res) => {
        const resClone = clone(res);
        // console.log("res clone", resClone);
        let locationPropsTemp = clone(locationProps);
        locationPropsTemp.forEach((item) => {
          res.forEach((itemApi) => {
            if (item.id === itemApi.id) {
              item.name = itemApi.name;
              item.products = itemApi.products;
            }
          });
        });
        setLocationProps(locationPropsTemp);
        setIsAnimating(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreload(false);
      });
  }, []);

  useEffect(() => {
    // console.log(local);
  }, [local]);

  function retira_acentos(str) {
    const com_acento =
      "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

    const sem_acento =
      "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let novastr = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < com_acento.length; a++) {
        if (str.substr(i, 1) == com_acento.substr(a, 1)) {
          novastr += sem_acento.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        novastr += str.substr(i, 1);
      }
    }
    return novastr;
  }

  useEffect(() => {
    if (product) {
      if (environment !== null && environment.products) {
        environment.products.map((locationProp, index) => {
          if (
            product ===
            retira_acentos(
              locationProp.name
                .toLowerCase()
                .replace(/ /g, "-")
                .replaceAll("/", "")
                .trim()
            )
          ) {
            enterProduct(index);
          }
        });
      }
    }
  }, [product, environment]);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  useEffect(() => {
    // console.log("locationProps", locationProps);
    let environmentTemp = locationProps.find((item) => {
      let routeName = item.name.trim().toLowerCase().replace(/\s/g, "-");
      routeName = routeName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      // console.log(routeName, local);
      return routeName === local;
    });

    let environmentsArray = [];

    locationProps.map((item) => {
      let routeName = item.name.trim().toLowerCase().replace(/\s/g, "-");
      routeName = routeName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      // console.log(routeName, local);
      environmentsArray.push({
        name: item.name,
        routeName: routeName,
        selected: false,
      });
    });
    environmentsArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    // console.log("environments", environmentsArray);
    setEnvironments(environmentsArray);
    var products = "";

    if (environmentTemp) {
      environmentTemp.products.forEach((item) => {
        if (local === "edificio-residencial") {
          products = productsEdificioResidencial;
        } else if (local === "hospital") {
          products = productsHospital;
        } else if (local === "escritorio") {
          products = productsEscritorio;
        } else if (local === "hotel") {
          products = productsHotel;
        } else if (local === "industria") {
          products = productsIndustria;
        } else if (local === "infraestrutura") {
          products = productsInfraestrutura;
        } else if (local === "escola") {
          products = productsEscola;
        } else if (local === "galpao") {
          products = productsGalpao;
        } else if (local === "parque-eolico") {
          products = productsParqueEolico;
        } else if (local === "residencial-unifamiliar") {
          products = productsResidenciaUnifamiliar;
        } else if (local === "shopping") {
          products = productsShopping;
        } else if (local === "ponte") {
          products = productsPonte;
        } else if (local === "aviario") {
          products = productsAviario;
        }

        if (products) {
          products.forEach((itemApi) => {
            if (item.id === itemApi.id) {
              // console.log("itemApi", itemApi);
              item.pinPos = itemApi.pinPos;
              item.scale = itemApi.scale ? itemApi.scale : 1;
              item.environmentArea = itemApi.environmentArea
                ? itemApi.environmentArea
                : 0;
              item.boardPos = itemApi.boardPos;
              item.animationSpeed = itemApi.animationSpeed;
              item.selected = itemApi.selected;
              item.hovered = itemApi.hovered;
              item.cameraIn = itemApi.cameraIn;
              item.cameraOut = itemApi.cameraOut;
            }
          });
        }
      });
      if (local === "edificio-residencial") {
        environmentTemp.products.push(productsEdificioResidencial[0]);
        environmentTemp.products.push(productsEdificioResidencial[1]);
      } else if (local === "hospital") {
        environmentTemp.products.push(productsHospital[0]);
        environmentTemp.products.push(productsHospital[1]);
      } else if (local === "escritorio") {
        environmentTemp.products.push(productsEscritorio[0]);
        environmentTemp.products.push(productsEscritorio[1]);
      } else if (local === "residencial-unifamiliar") {
        environmentTemp.products.push(productsResidenciaUnifamiliar[0]);
        environmentTemp.products.push(productsResidenciaUnifamiliar[1]);
      }
      setEnvironment(environmentTemp);
    }
  }, [locationProps]);

  useEffect(() => {
    // console.log(environments);
  }, [environments]);

  useEffect(() => {
    // if (environment !== null && location !== null) {
    //   console.log(location, environment.products[location].description);
    // } else {
    //   console.log(location, environment);
    // }
  }, [location]);

  return (
    <>
      {/* <Stats /> */}
      {/* <CamTweak refOrbit={refOrbit} /> */}
      {!preload && (
        <>
          <Header />
          <CliqueArraste
            className={`${showCliqueArraste && "on"} ${isMobile && "mobile"}`}
          />
          <InternaLocationPin className={isMobile && "mobile"}>
            <img src={pin} />{" "}
            <span>
              {environment !== null && environment.name.toLowerCase()}
            </span>
            <EnvironmentsMenu className={`on ${isMobile && "mobile"}`}>
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  title={""}
                  className="dropdown"
                >
                  {environments !== null &&
                    environments.map(
                      (element, index) =>
                        element.name !== environment.name && (
                          <Dropdown.Item
                            key={element.name}
                            eventKey={index}
                            onClick={() => goEnvironments(element.routeName)}
                          >
                            {element.name.toLowerCase()}
                          </Dropdown.Item>
                        )
                    )}
                </DropdownType>
              ))}
            </EnvironmentsMenu>
          </InternaLocationPin>
          <SectionContent
            environmentArea={environmentArea}
            setEnvironmentArea={setEnvironmentArea}
            setIsAnimating={setIsAnimating}
            setData={setData}
            locationProps={environment !== null && environment.products}
            setLocation={setLocation}
            location={location}
            isInsideSection={isInsideSection}
            setIsInsideSection={setIsInsideSection}
            environment={environment}
            refOrbit={refOrbit}
            local={local}
          />
          <Canvas
            mode={"concurrent"}
            // invalidateFrameloop={shouldInvalidate}
            performance={{ min: 0.5 }}
            className={"canvas"}
            shadows
            dpr={[0.25, 1]}
            frameloop={"always"}
            flat={false}
            linear={false}
            gl={{
              // alpha: true,
              outputColorSpace: THREE.SRGBColorSpace,
              toneMapping: THREE.NoToneMapping,
              antialias: true,
              logarithmicDepthBuffer: true,
              powerPreference: "high-performance",
            }}
            style={{
              position: "absolute",
              zIndex: 0,
              pointerEvents: "all",
              transform: burgerMenuOn
                ? "translateX(-40vw) translateY(20vh)"
                : isInsideSection
                ? "translateX(-40vw) translateY(0vh)"
                : "translateX(0vw) translateY(0vh)",
              transition: burgerMenuOn
                ? "transform 0.5s linear 0.2s"
                : isInsideSection
                ? "transform 0.5s linear .8s"
                : "transform 0.5s linear 0s",
            }}
            onClick={() => setShowCliqueArraste(false)}
            onTouchStart={() => setShowCliqueArraste(false)}
          >
            <PerspectiveCamera
              makeDefault
              // position={[data.camPosX, data.camPosY, data.camPosZ]}
              fov={data.fov}
              near={data.near}
              far={100000}
              zoom={1}
            />
            <WebGlListener />
            <color attach="background" args={["#aaddff"]} />
            {isBrowser && (
              <>
                <ambientLight intensity={1} />
                {/* <DirectionalLight
              castShadow={true}
              pos={[1060, 1000, 1016]}
              targetPos={[0, 0, 0]}
              intensity={1}
              lightColor={"#ffffff"}
              setIsAnimating={setIsAnimating}
            />
            <spotLight
              args={[0xffddee, 0.1]}
              position={[-1000, 1000, 1000]}
              castShadow
            /> */}
                {/* <hemisphereLight args={[0xffeeb1, 0x080820, 1]} /> */}
                <Suspense fallback={<PreloaderCanvas />}>
                  {local === "edificio-residencial" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Residencia
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.5}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "residencial-unifamiliar" && (
                    <>
                      <ambientLight intensity={0.9} />
                      <DirectionalLight
                        castShadow={true}
                        pos={[-5000, 11000, -2000]}
                        targetPos={[0, 0, 0]}
                        intensity={2}
                        lightColor={"#FDFBD3"}
                      />
                      <Casa
                        setShowCliqueArraste={setShowCliqueArraste}
                        position={[200, 0, -500]}
                        scale={150}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.3}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "hotel" && (
                    <>
                      <ambientLight intensity={0.3} />
                      <Hotel
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.03}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "hospital" && (
                    <>
                      <ambientLight intensity={0.9} />
                      <Hospital
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[120, 0, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.02}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "galpao" && (
                    <>
                      <ambientLight intensity={1} />
                      <Galpao
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[-500, 0, -1300]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.2}
                      />
                      <Environment files={abandonedPark} />
                    </>
                  )}
                  {local === "escritorio" && (
                    <>
                      <ambientLight intensity={1} />
                      <Comercial
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[150, 0, -800]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.5}
                      />
                      <Environment files={enviComercial} />
                    </>
                  )}
                  {local === "escola" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Escola
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[50, 0, 0]}
                        rotation={[0, Math.PI / 0.93, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.5}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "shopping" && (
                    <>
                      <ambientLight intensity={0.8} />
                      <Shopping
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[0, 0, -200]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "infraestrutura" && (
                    <>
                      <ambientLight intensity={0.5} />
                      <Tunel
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[110, 0, 1490]}
                        rotation={[0, Math.PI / 2, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0}
                      />
                      <color attach="background" args={["#ffffff"]} />
                      <fog attach="fog" color="white" near={200} far={8000} />
                      <Environment files={enviCasa} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "industria" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Industria
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[0, 0, 5500]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.5}
                      />
                      <DirectionalLight
                        castShadow={true}
                        pos={[6500, 500, -2500]}
                        targetPos={[5000, 0, -2000]}
                        intensity={0.7}
                        lightColor={"#ffffff"}
                        setIsAnimating={setIsAnimating}
                      />
                      <Environment files={abandonedPark} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "parque-eolico" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <ParqueEolico
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[0, 0, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.02}
                      />
                      <Environment files={abandonedPark} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "ponte" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Cidade
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={30}
                        refOrbit={refOrbit}
                      />
                      <DirectionalLight
                        castShadow={true}
                        pos={[6500, 400, -2500]}
                        targetPos={[5000, 0, -2000]}
                        intensity={0.3}
                        lightColor={"#ffffff"}
                        setIsAnimating={setIsAnimating}
                      />
                      <Environment files={enviCasa} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "aviario" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Cidade
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={30}
                        refOrbit={refOrbit}
                      />
                      <DirectionalLight
                        castShadow={true}
                        pos={[6500, 400, -2500]}
                        targetPos={[5000, 0, -2000]}
                        intensity={0.3}
                        lightColor={"#ffffff"}
                        setIsAnimating={setIsAnimating}
                      />
                      <Environment files={enviCasa} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {(local === "edificio-residencial" ||
                    local === "hospital" ||
                    local === "escritorio" ||
                    local === "hotel" ||
                    local === "industria" ||
                    local === "infraestrutura" ||
                    local === "escola" ||
                    local === "galpao" ||
                    local === "parque-eolico" ||
                    local === "residencial-unifamiliar" ||
                    local === "shopping" ||
                    local === "ponte" ||
                    local === "aviario") && (
                    <BillboardMenu
                      enterProduct={enterProduct}
                      follow={true}
                      lockX={false}
                      lockY={false}
                      lockZ={false}
                      local={local}
                      location={location}
                      setLocation={setLocation}
                      isHover={isHover}
                      setIsHover={setIsHover}
                      setIsAnimating={setIsAnimating}
                      setData={setData}
                      locationProps={
                        environment !== null && environment.products
                      }
                      refOrbit={refOrbit}
                      isInsideSection={isInsideSection}
                      setIsInsideSection={setIsInsideSection}
                      environmentArea={environmentArea}
                      setEnvironmentArea={setEnvironmentArea}
                      navigate={navigate}
                    />
                  )}
                  {/* <Environment files={enviCasa} /> */}
                  {local === "shopping" ? (
                    <CameraControls
                      ref={refOrbit}
                      minAzimuthAngle={-Math.PI / 2.6}
                      maxAzimuthAngle={Math.PI / 2.6}
                      minPolarAngle={data.minPolarAngle}
                      maxPolarAngle={data.maxPolarAngle}
                      data={data}
                      isAnimating={isAnimating}
                      setIsAnimating={setIsAnimating}
                      local={local}
                    />
                  ) : (
                    <CameraControls
                      ref={refOrbit}
                      minPolarAngle={data.minPolarAngle}
                      maxPolarAngle={data.maxPolarAngle}
                      data={data}
                      isAnimating={isAnimating}
                      setIsAnimating={setIsAnimating}
                      local={local}
                    />
                  )}
                  <AnimateCamera
                    data={data}
                    setData={setData}
                    locationProps={environment !== null && environment.products}
                    location={location}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isHover={isHover}
                    refOrbit={refOrbit}
                    local={local}
                  />
                </Suspense>
              </>
            )}
            {isMobile && (
              <>
                {/* <ambientLight intensity={0.25} /> */}
                {/* <DirectionalLight
              castShadow={true}
              pos={[1060, 1000, 1016]}
              targetPos={[0, 0, 0]}
              intensity={1}
              lightColor={"#ffffff"}
              setIsAnimating={setIsAnimating}
            />
            <spotLight
              args={[0xffddee, 0.1]}
              position={[-1000, 1000, 1000]}
              castShadow
            /> */}
                {/* <hemisphereLight args={[0xffeeb1, 0x080820, 1]} /> */}
                <Suspense fallback={<PreloaderCanvas />}>
                  {local === "edificio-residencial" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Residencia
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.5}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "residencial-unifamiliar" && (
                    <>
                      <ambientLight intensity={0.9} />
                      <DirectionalLight
                        castShadow={true}
                        pos={[-5000, 11000, -2000]}
                        targetPos={[0, 0, 0]}
                        intensity={2}
                        lightColor={"#FDFBD3"}
                      />
                      <Casa
                        setShowCliqueArraste={setShowCliqueArraste}
                        position={[200, 0, -500]}
                        scale={150}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.3}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "hotel" && (
                    <>
                      <ambientLight intensity={0.3} />
                      <Hotel
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.03}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "hospital" && (
                    <>
                      <ambientLight intensity={0.9} />
                      <Hospital
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[120, 0, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.02}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "galpao" && (
                    <>
                      <ambientLight intensity={1} />
                      <Galpao
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[-500, 0, -1300]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.2}
                      />
                      <Environment files={abandonedPark} />
                    </>
                  )}
                  {local === "escritorio" && (
                    <>
                      <ambientLight intensity={1} />
                      <Comercial
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[150, 0, -800]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.5}
                      />
                      <Environment files={enviComercial} />
                    </>
                  )}
                  {local === "escola" && (
                    <>
                      <ambientLight intensity={0.9} />
                      <Escola
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[50, 0, 0]}
                        rotation={[0, Math.PI / 0.93, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.01}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "shopping" && (
                    <>
                      <ambientLight intensity={0.8} />
                      <Shopping
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[0, 0, -200]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0}
                      />
                      <Environment files={enviCasa} />
                    </>
                  )}
                  {local === "infraestrutura" && (
                    <>
                      <ambientLight intensity={0.5} />
                      <Tunel
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[110, 0, 1490]}
                        rotation={[0, Math.PI / 2, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0}
                      />
                      <color attach="background" args={["#ffffff"]} />
                      <fog attach="fog" color="white" near={200} far={8000} />
                      <Environment files={enviCasa} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "industria" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Industria
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[0, 0, 5500]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.5}
                      />
                      <DirectionalLight
                        castShadow={true}
                        pos={[6500, 500, -2500]}
                        targetPos={[5000, 0, -2000]}
                        intensity={0.7}
                        lightColor={"#ffffff"}
                        setIsAnimating={setIsAnimating}
                      />
                      <Environment files={abandonedPark} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "parque-eolico" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <ParqueEolico
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={150}
                        position={[0, 0, 0]}
                        setIsAnimating={setIsAnimating}
                        setData={setData}
                        location={location}
                        setLocation={setLocation}
                        locationProps={
                          environment !== null && environment.products
                        }
                        refOrbit={refOrbit}
                        isInsideSection={isInsideSection}
                        setIsInsideSection={setIsInsideSection}
                        envMapIntensity={0.02}
                      />
                      <Environment files={abandonedPark} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "ponte" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Cidade
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={30}
                        refOrbit={refOrbit}
                      />
                      <DirectionalLight
                        castShadow={true}
                        pos={[6500, 400, -2500]}
                        targetPos={[5000, 0, -2000]}
                        intensity={0.3}
                        lightColor={"#ffffff"}
                        setIsAnimating={setIsAnimating}
                      />
                      <Environment files={enviCasa} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {local === "aviario" && (
                    <>
                      <ambientLight intensity={0.4} />
                      <Cidade
                        setShowCliqueArraste={setShowCliqueArraste}
                        scale={30}
                        refOrbit={refOrbit}
                      />
                      <DirectionalLight
                        castShadow={true}
                        pos={[6500, 400, -2500]}
                        targetPos={[5000, 0, -2000]}
                        intensity={0.3}
                        lightColor={"#ffffff"}
                        setIsAnimating={setIsAnimating}
                      />
                      <Environment files={enviCasa} />
                      {/* <Effects /> */}
                    </>
                  )}
                  {(local === "edificio-residencial" ||
                    local === "hospital" ||
                    local === "escritorio" ||
                    local === "hotel" ||
                    local === "industria" ||
                    local === "infraestrutura" ||
                    local === "escola" ||
                    local === "galpao" ||
                    local === "parque-eolico" ||
                    local === "residencial-unifamiliar" ||
                    local === "shopping" ||
                    local === "ponte" ||
                    local === "aviario") && (
                    <BillboardMenu
                      enterProduct={enterProduct}
                      follow={true}
                      lockX={false}
                      lockY={false}
                      lockZ={false}
                      local={local}
                      location={location}
                      setLocation={setLocation}
                      isHover={isHover}
                      setIsHover={setIsHover}
                      setIsAnimating={setIsAnimating}
                      setData={setData}
                      locationProps={
                        environment !== null && environment.products
                      }
                      refOrbit={refOrbit}
                      isInsideSection={isInsideSection}
                      setIsInsideSection={setIsInsideSection}
                      environmentArea={environmentArea}
                      setEnvironmentArea={setEnvironmentArea}
                      navigate={navigate}
                    />
                  )}
                  {/* <Environment files={enviCasa} /> */}
                  {local === "shopping" ? (
                    <CameraControls
                      ref={refOrbit}
                      minAzimuthAngle={-Math.PI / 3}
                      maxAzimuthAngle={Math.PI / 3}
                      minPolarAngle={data.minPolarAngle}
                      maxPolarAngle={data.maxPolarAngle}
                      data={data}
                      isAnimating={isAnimating}
                      setIsAnimating={setIsAnimating}
                      local={local}
                    />
                  ) : (
                    <CameraControls
                      ref={refOrbit}
                      minPolarAngle={data.minPolarAngle}
                      maxPolarAngle={data.maxPolarAngle}
                      data={data}
                      isAnimating={isAnimating}
                      setIsAnimating={setIsAnimating}
                      local={local}
                    />
                  )}
                  <AnimateCamera
                    data={data}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    refOrbit={refOrbit}
                    local={local}
                  />
                </Suspense>
              </>
            )}

            {/* <AdaptivePixelRatio /> */}
            <AdaptiveDpr />
            {/* <AdaptiveEvents /> */}
            <BakeShadows />
          </Canvas>
        </>
      )}
    </>
  );
};

export default Interna;
