export const configData = {
  axis: true,
  animationSpeed: 1,
  camPosX: 389.3784394372835,
  camPosY: 258.63605651465696,
  camPosZ: -309.01582029124063,
  near: 0.1,
  far: 1500,
  fov: 30,
  zoom: 1,
  focusDistance: 0.092,
  focalLength: 1.0,
  bokehScale: 3.68,
  height: 1080,
  targetVisible: true,
  targetPosX: 2,
  targetPosY: 20,
  targetPosZ: 0,
  // minPolarAngle: Math.PI / 4.5,
  minPolarAngle: Math.PI / 4.5,
  maxPolarAngle: Math.PI / 2.4,
  // maxPolarAngle: Math.PI,
  minDistance: 400,
  maxDistance: 650,
  enableZoom: true,
  enablePan: false,
  enableRotate: true,
  rotateSpeed: 0.5,
  autoRotate: false,
  enableDamping: true,
  dampingFactor: 0.1,
  backgroundColor: "#07b4ec",
  fog: true,
  fogColor: "#07b4ec",
  fogNear: 700,
  fogFar: 1200,
  menuOpacity: 0,
};

export const configDataMobile = {
  axis: true,
  animationSpeed: 1,
  camPosX: 420,
  camPosY: 400,
  camPosZ: 420,
  near: 0.1,
  far: 1500,
  fov: 30,
  zoom: 1,
  focusDistance: 0.092,
  focalLength: 1.0,
  bokehScale: 3.68,
  height: 1080,
  targetVisible: true,
  targetPosX: -10,
  targetPosY: -10,
  targetPosZ: 0,
  minPolarAngle: Math.PI / 4.5,
  maxPolarAngle: Math.PI / 3,
  minDistance: 400,
  maxDistance: 850,
  enableZoom: true,
  enablePan: false,
  enableRotate: true,
  rotateSpeed: 0.5,
  autoRotate: false,
  enableDamping: true,
  dampingFactor: 0.1,
  backgroundColor: "#07b4ec",
  fog: false,
  fogColor: "#07b4ec",
  fogNear: 600,
  fogFar: 850,
  menuOpacity: 0,
};

export const locationsOut = [
  {
    id: 4,
    name: "Hotel",
    materialId: "Hotel",
    thumb: `/assets/images/hotel.jpg`,
    description: "Descrição do Hotel",
    pinPos: [67, 70, 181.5],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 250.2519929070977,
        y: 197.45508224024726,
        z: 47.578797677008026,
      },
      target: {
        x: -30.38612204881724,
        y: 13.098269670453742,
        z: 236.39495663046307,
      },
      posMobile: {
        x: 250.2519929070977,
        y: 197.45508224024726,
        z: 47.578797677008026,
      },
      targetMobile: {
        x: -30.38612204881724,
        y: 13.098269670453742,
        z: 236.39495663046307,
      },
    },
    cameraOut: {
      pos: {
        x: 359.34204551447453,
        y: 800,
        z: -236.80639784880282,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 3,
    name: "Hospital",
    materialId: "Hospital.001",
    thumb: `/assets/images/hospital.jpg`,
    description: "Descrição do Hospital",
    pinPos: [40, 35, 132],
    boardPos: [-46, 72, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 259.37014715819134,
        y: 240.08343689485434,
        z: -79.86590985732694,
      },
      target: {
        x: 50.197747327062125,
        y: 71.22459487165267,
        z: 117.79753777840627,
      },
      posMobile: {
        x: 259.37014715819134,
        y: 240.08343689485434,
        z: -79.86590985732694,
      },
      targetMobile: {
        x: 50.197747327062125,
        y: 71.22459487165267,
        z: 117.79753777840627,
      },
    },
    cameraOut: {
      pos: {
        x: 409.5915888725715,
        y: 800,
        z: 95.79036969117942,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 7,
    name: "Parque Eólico",
    materialId: ["Energia.001", "Helice.001"],
    thumb: `/assets/images/parqueeolico.jpg`,
    description: "Descrição Energia Eólica",
    pinPos: [50, 100, -78.5],
    boardPos: [-39, 60, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 258.5116497444682,
        y: 212.50933107918092,
        z: -359.2810299131539,
      },
      target: {
        x: -19.073886144777042,
        y: 61.17390167592338,
        z: -1.0269369709013068,
      },
      posMobile: {
        x: 258.5116497444682,
        y: 212.50933107918092,
        z: -359.2810299131539,
      },
      targetMobile: {
        x: -19.073886144777042,
        y: 61.17390167592338,
        z: -1.0269369709013068,
      },
    },
    cameraOut: {
      pos: {
        x: 322.8775816760988,
        y: 800,
        z: 319.39101969793995,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 1,
    name: "Edifício Residencial",
    materialId: "EdComercial.001",
    thumb: `/assets/images/edres.jpg`,
    description: "Descrição do Prédio",
    pinPos: [-74.5, 100, 121],
    boardPos: [-37, 55, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -328.09771773170263,
        y: 249.21225512884587,
        z: -61.180220477289026,
      },
      target: {
        x: -51.34293783550753,
        y: 76.29234932426591,
        z: 150.87118885250484,
      },
      posMobile: {
        x: -328.09771773170263,
        y: 249.21225512884587,
        z: -61.180220477289026,
      },
      targetMobile: {
        x: -51.34293783550753,
        y: 76.29234932426591,
        z: 150.87118885250484,
      },
    },
    cameraOut: {
      pos: {
        x: -232.10465932628063,
        y: 800,
        z: 423.2362039106099,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 9,
    name: "Galpão",
    materialId: "Galpao.001",
    thumb: `/assets/images/galpao.jpg`,
    description: "Descrição do Galpao",
    pinPos: [-53, 30, 17],
    boardPos: [-48, 78, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -214.56344811996556,
        y: 226.8572401208952,
        z: -95.81216974582092,
      },
      target: {
        x: -26.958509673994723,
        y: 18.50791338640532,
        z: 46.466728517019746,
      },
      posMobile: {
        x: -214.56344811996556,
        y: 226.8572401208952,
        z: -95.81216974582092,
      },
      targetMobile: {
        x: -26.958509673994723,
        y: 18.50791338640532,
        z: 46.466728517019746,
      },
    },
    cameraOut: {
      pos: {
        x: -368.13871153653344,
        y: 800,
        z: 260.94900172100625,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 8,
    name: "Escola",
    materialId: "Escola.001",
    thumb: `/assets/images/escola.jpg`,
    description: "Descrição da Escola",
    pinPos: [-72, 35, 70],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -260.9101842546074,
        y: 218.60986144144664,
        z: 35.81960021237171,
      },
      target: {
        x: -26.775777128034655,
        y: 12.001811033348964,
        z: 90.3728029270921,
      },
      posMobile: {
        x: -260.9101842546074,
        y: 218.60986144144664,
        z: 35.81960021237171,
      },
      targetMobile: {
        x: -26.775777128034655,
        y: 12.001811033348964,
        z: 90.3728029270921,
      },
    },
    cameraOut: {
      pos: {
        x: -367.72637319839043,
        y: 800,
        z: 260.6567145003765,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 10,
    name: "Residencial Unifamiliar",
    materialId: "Casa.001",
    thumb: `/assets/images/casa.jpg`,
    description: "Descrição da Casa",
    pinPos: [-30, 30, 73],
    boardPos: [-46, 76, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 166.09789243579706,
        y: 189.82005888774557,
        z: 175.40271546175632,
      },
      target: {
        x: -62.57704169553081,
        y: 35.93580385919411,
        z: 54.1084933395482,
      },
      posMobile: {
        x: 166.09789243579706,
        y: 189.82005888774557,
        z: 175.40271546175632,
      },
      targetMobile: {
        x: -62.57704169553081,
        y: 35.93580385919411,
        z: 54.1084933395482,
      },
    },
    cameraOut: {
      pos: {
        x: 384.7376734644313,
        y: 800,
        z: 153.8338769708527,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 11,
    name: "Shopping",
    materialId: "Shopping.001",
    thumb: `/assets/images/shopping.jpg`,
    description: "Descrição do Shopping",
    pinPos: [48, 40, 73],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 301.02842519255887,
        y: 226.75160576024547,
        z: 263.15276576613155,
      },
      target: {
        x: 29.45050192701496,
        y: 38.72004429658925,
        z: 61.60481864670463,
      },
      posMobile: {
        x: 301.02842519255887,
        y: 226.75160576024547,
        z: 263.15276576613155,
      },
      targetMobile: {
        x: 29.45050192701496,
        y: 38.72004429658925,
        z: 61.60481864670463,
      },
    },
    cameraOut: {
      pos: {
        x: 469.5791897838509,
        y: 800,
        z: 81.5428282599478,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 2,
    name: "Escritório",
    materialId: "Escritorio.001",
    thumb: `/assets/images/escritorio.jpg`,
    description: "Descrição do Escritório",
    pinPos: [67, 34, 23],
    boardPos: [-46, 74, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 255.61084376835254,
        y: 187.95571546150754,
        z: -218.79153345626145,
      },
      target: {
        x: 35.859503857222776,
        y: 54.255876740815815,
        z: 46.77695956336646,
      },
      posMobile: {
        x: 255.61084376835254,
        y: 187.95571546150754,
        z: -218.79153345626145,
      },
      targetMobile: {
        x: 35.859503857222776,
        y: 54.255876740815815,
        z: 46.77695956336646,
      },
    },
    cameraOut: {
      pos: {
        x: 411.0198552005528,
        y: 800,
        z: -125.91181080290448,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 5,
    name: "Indústria",
    materialId: "Industria",
    thumb: `/assets/images/industria.jpg`,
    description: "Descrição da Ind",
    pinPos: [-45, 34, -57],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -321.4441887358565,
        y: 154.6881535397084,
        z: -223.14916996101056,
      },
      target: {
        x: 12.91844034337876,
        y: 23.25240519150531,
        z: -26.30068538476468,
      },
      posMobile: {
        x: -321.4441887358565,
        y: 154.6881535397084,
        z: -223.14916996101056,
      },
      targetMobile: {
        x: 12.91844034337876,
        y: 23.25240519150531,
        z: -26.30068538476468,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 6,
    name: "Infraestrutura",
    materialId: "Industria",
    thumb: `/assets/images/tunel.jpg`,
    description: "Descrição da Infraestrutura",
    pinPos: [-22, 34, -23],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -274.7148297344057,
        y: 216.5371163246577,
        z: 149.65913039458093,
      },
      target: {
        x: -0.13847003347663492,
        y: 40.916207456583116,
        z: -24.863194289481786,
      },
      posMobile: {
        x: -274.7148297344057,
        y: 216.5371163246577,
        z: 149.65913039458093,
      },
      targetMobile: {
        x: -0.13847003347663492,
        y: 40.916207456583116,
        z: -24.863194289481786,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 12,
    name: "Aviário",
    materialId: [
      "TerrenoAviario",
      "Arvores",
      "Wallboard",
      "PisoConcreto",
      "Bosque",
      "Canal",
      "Silo",
      "TelhaTopComfort",
      "Metal liso.001",
      "Metal galvanizado.001",
      "Grama_A",
      "Grama_B",
      "Grama_C",
    ],
    thumb: `/assets/images/aviario.jpg`,
    description: "Descrição do Aviário",
    pinPos: [38, 35, -208],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 139.32208853685134,
        y: 168.83729433359485,
        z: -513.0295860297775,
      },
      target: {
        x: -29.712145177105942,
        y: -1.1357101056193517,
        z: -17.968757521119922,
      },
      posMobile: {
        x: 105.00483292847626,
        y: 218.76022379523226,
        z: -603.0171159338682,
      },
      targetMobile: {
        x: -0.008020484931703589,
        y: 19.909583177798034,
        z: -0.09465289895813647,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 13,
    name: "Ponte",
    materialId: "Ponte",
    thumb: `/assets/images/ponte.jpg`,
    description: "Descrição da Ponte",
    pinPos: [-18, 60, -135],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -321.7478461261539,
        y: 227.15705512408323,
        z: -321.6855957586666,
      },
      target: {
        x: 49.8453999924961,
        y: -2.8110409647036594,
        z: -72.68862580016388,
      },
      posMobile: {
        x: -321.7478461261539,
        y: 227.15705512408323,
        z: -321.6855957586666,
      },
      targetMobile: {
        x: 49.8453999924961,
        y: -2.8110409647036594,
        z: -72.68862580016388,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
];
