import axios from "axios";

const api = axios.create({
  baseURL: "https://cidade.saintgobainconstrucao.com.br/api/api",
});

const getStorageWithExpire = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expire) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

function setLocalStorage(key, value, timeToExpire) {
  localStorage.setItem(
    key,
    JSON.stringify({
      value: value,
      expires: timeToExpire,
    })
  );
}

export async function getToken() {
  let token = getStorageWithExpire("token");

  if (token === null) {
    const response = await api
      .post("/auth/login", {
        email: "leonardo98barros@gmail.com",
        password: "123456",
      })
      .then((res) => {
        // console.log("token novo", res.data.access_token);
        setLocalStorage("token", res.data.access_token, res.data.expires_at);
        return res.data.access_token;
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    // console.log("token do local storage", token);
    return token;
  }
}

export async function getAllLocals() {
  api
    .get("/v1/environments")
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getLocal(id, token) {
  return api
    .get(`/v1/environments/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    })
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getLocalProducts(localId) {
  const clone = require("rfdc")();
  return api
    .get(`v1/home/environments`)
    .then((res) => {
      let test = clone(res.data.data);
      // console.log("api response", test);
      var result = null;
      if (localId !== undefined && localId !== null && localId !== "") {
        result = res.data.data.find((obj) => {
          return obj.id === localId;
        });
      } else {
        result = res.data.data;
      }
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getAProduct(id, token) {
  return api
    .get(`/v1/environments/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    })
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getSolutionsProducts() {
  return api
    .get(`/v1/home/solutions`)
    .then((res) => {
      let result = res.data.data;
      // console.log(result);
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getCategories(token) {
  return api
    .get(`v1/home/environments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export default api;
